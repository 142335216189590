.p-sidebar {
    background: $overlayContentBg;
    color: $panelContentTextColor;
    border: $overlayContentBorder;
    box-shadow: $overlayContainerShadow;

    .p-sidebar-header {
        display: none;
    }

    .p-sidebar-content {
        padding: $panelContentPadding;
    }
}
