$default-animation-speed: .2s;
$green-background-image: '../../../../public/assets/images/bg-page.svg';

.inline-block {
  display: inline-block !important;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.full-width {
  display: block;
  width: 100%;
}

$z-index: (
        table-fixed: auto,
        affix: 10,
        back-top: 10,
        badge: 10,
        picker-panel: 10,
        popup-close: 10,
        modal: 1000,
        modal-mask: 1000,
        modal-content: 1001,
        message: 1010,
        notification: 1010,
        popover: 1030,
        dropdown: 1050,
        picker: 1050,
        tooltip: 1060,
        navigation : 3,
        input-label : 1000,
        select-dropdown : 1002,
        global-loader:2000,
        details-panel: 990,
        details-panel-title: 1002,
        details-panel-close: 1003
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}

.pointer {
  cursor: pointer;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.bold {
  font-weight: bold;
}

.flex-nowrap{
  flex-wrap: nowrap;
}

.clear-fix {
  clear: both;
}
