.p-accordion {
    .p-accordion-header {
        .p-accordion-header-link {
            padding: $accordionHeaderPadding;
            border: $accordionHeaderBorder;
            color: $accordionHeaderTextColor;
            background: $accordionHeaderBg;
            font-weight: $accordionHeaderFontWeight;
            border-radius: $borderRadius;
            transition: $listItemTransition;
            border-radius: 0.5rem;

            .p-accordion-toggle-icon {
                margin-right: $inlineSpacing;
            }
        }

        &:not(.p-highlight):not(.p-disabled):hover {
            .p-accordion-header-link {
                background: $accordionHeaderHoverBg;
                color: $accordionHeaderTextHoverColor;
            }
        }

        &:not(.p-disabled).p-highlight {
            .p-accordion-header-link {
                background: $accordionHeaderActiveBg;
                border-color: $accordionHeaderActiveBorderColor;
                color: $accordionHeaderTextActiveColor;
                padding-bottom: 0.25rem;
                border-radius: 0.5rem 0.5rem 0 0;
                border-bottom: none;
            }
        }
    }

    .p-accordion-tab:hover {
        .p-accordion-header-link,
        .p-accordion-content  {
            border-color: $accordionHeaderActiveHoverBorderColor !important;
        }
    }

    .p-accordion-content {
        padding: $accordionContentPadding;
        background: $accordionContentBg;
        border: $accordionContentBorder;
        border-top: none;
        color: $accordionContentTextColor;
        border-radius: 0 0 0.5rem 0.5rem;
    }

    .p-accordion-tab {
        margin-bottom: $accordionSpacing;

    }
}
