.x-axis-group text{
  font-family: $font-family-tertiary;
  font-size: $font-size-s;
  fill: $color-navy;
}
.y-axis-group text{
  font-family: $font-family-tertiary;
  font-size: $font-size-xs;
  fill: $color-navy;
}

.tooltip {
  font-family: $font-family-tertiary;
  font-size: $font-size-xs;
  position: absolute;
  height: auto;
  padding: $spacing-s;
  background-color: white;
  border-radius: $border-radius;
  box-shadow: $spacing-s $spacing-s $spacing-sm grey;
  pointer-events: none;
  display: table-cell;
  opacity: 0;
}

.barchart-wrapper {
  text-align: right;
}

.bar {
  fill: $color-negative-impact;
  opacity: 0.7;
}

.barNegative {
  fill: $color-positive-impact;
  opacity: 0.7;
}

.D3Chart-container {
  border: 1px solid $color-grey-lighter;
  background-color: white;
  margin-bottom: $ANT-spacing-16;
  padding-top: $ANT-spacing-16;
}

.filter-low-impact-switcher {
  position: absolute;
  top: $spacing-sm;
  right: $spacing-sm;
}