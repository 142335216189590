.model .workspace > .process .process-col.process-list {
    padding: 0px $spacing-ml;
    @include breakpoint('laptop'){
        padding: 0px $spacing-sm;
    }
    > .edit input {
        background-color: $color-steel-light;
        border-radius: $border-radius;
        width: 100%;
        text-align: center;
    }
    button.add {
        height: $spacing-5xl;
        width: 100%;
    }
    & > .item.edit.process {
        .remove-item,
        .details-icons {
            display: none;
        }
    }
    .title-process_icons {
      text-align: right;
      .anticon {
        margin: $ANT-spacing-4;
      }
    }
    .input-list {
        padding-right: $spacing-sm;
        @include breakpoint('laptop'){
            padding-right: $spacing-s;
        }
        button.add {
            height: $spacing-xl;
            margin-bottom: $spacing-sm;
            padding-left: $spacing-2xl;
            text-align: left;
        }
        input.name {
            text-align: left;
        }
        .edit > input {
            text-align: left;
        }
        .item {
            align-items: center;
            display: flex;
            padding-left: $spacing-2xl;
            padding-right: $spacing-s;

            &.edit .remove-item{
                display: none;
            }
            .details-highlight{
                right: $spacing-2xl;
            }
            .details-icons {
                position: absolute;
                top: $spacing-xs;
                right: -$spacing-4xl;
            }
            .remove-item {
                right: auto;
                left: $spacing-s;
                top:  $spacing-xs;
            }
            .refer-icon {
                right: $spacing-5xl;
            }
        }
    }
    .item {
        display: block;
        margin-bottom: $spacing-sm;
        min-height: $spacing-xl;
        padding: 0px;
        .autocomplete {
            width: 100%;
        }
        .content {
          background-color: $color-background-light;
          margin-top: -1px;
          border: 1px solid $color-steel-light;
          padding: $spacing-sm;
        }
        .title {
            background-color: $color-background-light;
            border: 1px solid $color-steel-light;
            padding: $ANT-spacing-8 $ANT-spacing-8 $ANT-spacing-4;
            position: relative;
            text-align: center;
            cursor: pointer;
            input.name {
                text-align: center;
                padding: 0px;
            }
            &.selected{
              background: $color-steel-bright;
              .name{
                color: $color-text-primary;
              }
            }
        }

        .details-panel{
            background-color: $color-steel-lighter;
            top: $spacing-xl;
        }
    }
    .output-list {
        padding-left: $spacing-sm;
        text-align: right;
        @include breakpoint('laptop'){
            padding-left: $spacing-s;
        }
        button.add {
            height: $spacing-xl;
            margin-bottom: $spacing-sm;
            padding-right: $spacing-2xl;
            text-align: right;
        }
        input.name {
            text-align: right;
        }
        .edit > input {
            text-align: right;
        }
        .item {
            align-items: center;
            display: flex;
            justify-content: flex-end;
            padding-right: $spacing-2xl;
            padding-left: $spacing-s;
            &.edit .remove-item{
                display: none;
            }
            .details-highlight{
                left: $spacing-2xl;
            }
            .details-icons {
                position: absolute;
                top: $spacing-xs;
                left: -$spacing-xl;
            }
            .remove-item {
                left: auto;
                right: $spacing-s;
                top: $spacing-xs;
            }
            .name{
                text-align: right;
            }
        }
    }
}
