$account-page-card-width: 1300px;

.account-page{
  min-height: 100vh;
  background: url($green-background-image) no-repeat top left;
  background-size: cover;

  & > div{
    padding: $grid-padding $grid-padding-x4;
    .ant-typography{
      font-size: $font-size-m;
      line-height: $line-height-tall;
      color: $color-text-grey-dark;
    }
  }
  .title{
    color: $color-text-title;
    font-size: $font-size-welcome-title;
    padding-top: 2em;
  }
  .content-container{
    max-width: $account-page-card-width;
    .subtitle{
      max-width: 50%;
      padding-top: 2em;
      .ant-typography{
        font-size: $font-size-welcome-subtitle;
        &.question{
          padding: 1em 0;
        }
      }
    }
    .content{
      .card{
        border-radius: $border-radius;
        .ant-card-body{
          padding: 0;
        }
        .team-image{
          height: auto;
          background: url('../../../../public/assets/images/team.jpg') no-repeat left center;
          background-size: cover;
        }
        .text-container{
          padding: $grid-padding-x2;
        }
        .button{
          text-transform: uppercase;
          padding-right: 2em;
          padding-left: 2em;
          font-size: $font-size-s;
        }
      }
    }
  }
}

.meeting-box{
  .ant-modal-body{
    pointer-events: none;
  }
  .ant-modal-close-x{
    width: $ANT-spacing-16;
    height: $ANT-spacing-16;
    margin-right: $ANT-spacing-4;
  }
  .meetings-iframe-container{
    iframe{
      pointer-events: auto;
      position: relative;
      @include z-index(modal-content);
    }
  }
}
