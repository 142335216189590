.reference-result {
  $root: &;

  &__list {

    .ant-list-pagination {
      margin-top: $spacing-sm;
    }

    .ant-list-item-action {
      margin-left: $spacing-ml;
    }
  }
  
  &__resultHeader {
    width: 100%;
    height: $spacing-ml;
  }

  &__allResult {
    font-size: $font-size-sm;
    margin-bottom: $spacing-xxs;
    float: left;
  }

  &__downIcon {
    font-size: $font-size-sm;
    vertical-align: -3px;
  }

  &__sort {
    font-size: $font-size-s;
    float: right;
    display: none; // TO DO: To be done with alphabetically sort ticket
  }

  &__item {
    padding: 14px $spacing-ml !important;
    border-radius: $spacing-ss;
    background: $color-grey-bright;
    border: none;
    margin-top: $ANT-spacing-8;
    align-items: flex-start !important;
    cursor: pointer;
    clear: both;

    &--opened {
      #{$root}__add {
        display: block;
      }
    }

    &:hover {
      background-color: $color-grey-bright-1;

      #{$root}__add,
      #{$root}__info--more {
        display: block !important;
      }
    }
  }

  &__card {
    max-width: calc(100% - #{$spacing-9xl});

    &--selected {
      width: calc(100% - 175px);
    }
  }

  &__add {
    display: none;
    float: right;
    font-size: $font-size-s;
  }
  
  &__name{
    font-family: $font-family-tertiary;
    font-weight: $semibold;
    color: $color-text-grey-dark;
    font-size: $font-size-s;
    line-height: 18px;
    width: 100%;

    &--closed {
      @extend .crop-text;
    }
  }

  &__subtitle {
    margin: 0;
    margin-right: $spacing-ml;
    font-size: $font-size-xs;
    line-height: $ANT-spacing-16;
    padding-top: 1px;
    display: block;
    float: left;
    height: $ANT-spacing-16;
    overflow: hidden;
  }

  &__label {
    color: $color-text-grey-dark;
    font-weight: $semibold;
  }

  &__value {
    clear: both;
    color: $color-grey;
    margin-right: $spacing-xxs;
  }

  &__divider {
    border-right: 1px solid $color-grey
  }

  &__content {
    padding-top: $spacing-2xl;
    width: 100%;
    clear: both;
  }

  &__attr {
    float: left;
    width: 50%;
    margin-bottom: $spacing-ml;
  }

  &__description {
    float: left;
    width: 100%;
  }

  &__info {
    padding: 0;
    font-size: $font-size-xs;
    height: $ANT-spacing-16;
    border: none;
    display: block;
    float: left;

    &--more {
      display: none !important;
    }
  }

}