form.compact {
  .ant-form-item {
    display: flex;
    flex-direction: row;

    .ant-form-item-control {
      line-height: inherit;
    }

    .ant-input,
    .ant-input-number,
    .ant-select-selection,
    .ant-select-search--inline .ant-select-search__field {
      border-radius: 0 $form-input-border-radius $form-input-border-radius 0
    }

    .ant-input-number {
      width: 100%;

      &:hover,
      &:focus {
        box-shadow: none;
        border-color: $color-green;
      }
    }

    .ant-form-item-label {
      label {
        color: rgba(0, 0, 0, 0.65);
      }

      font-family: "Rubik", sans-serif;
      font-variant: tabular-nums;
      font-weight: normal;
      font-size: 14px;

      align-content: flex-end;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: $color-background-light;
      border: 1px solid $color-divider;
      border-right: none;
      transition: all 0.3s;
      border-radius: $form-input-border-radius 0 0 $form-input-border-radius;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      > label::after {
        content: '';
      }
    }
  }
}
