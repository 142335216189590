.workspace-user-permissions,
.lifecycle-user-permissions {
  .role-container{
    ul, li, .policies{
      width: 100%;
    }
    ul > li{
      width: 100%;
      @extend .crop-text;
    }
    .ant-collapse-header{
      font-weight: normal !important;
    }
  }
  .add-role-container,
  .remove-role-container {
    margin-top: $spacing-sm;
  }
  .select-new-role{
    width: 100%;
  }
  .inheritance-switch {

     button{
       margin-left: $spacing-sm
     }
     border-bottom: 1px solid $color-divider;
     padding-bottom: $spacing-m;
  }
}
