.model .workspace > .process .process-col.output-list {
    button.add {
        padding-left: $spacing-2xl;
        text-align: left;
    }
    .item {
        align-items: center;
        display: flex;
        &:hover:not(.edit), &.on {
            background-color: $color-steel-light;
        }
        .details-highlight{
            right: $spacing-2xl;
        }
        .details-icons {
            position: absolute;
            top: $spacing-s;
            right: -$spacing-xl;
        }
    }
}
