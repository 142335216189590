.impact-arrow{
  white-space: nowrap;
  &--large{
    font-size: $font-size-m;
  }
  &__icon{
    display: inline-block;
    color: white;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: -$spacing-xs;
    &--positive{
      background: $color-positive-impact;
      border: 3px solid $color-positive-impact;
      opacity: .8;
    }
    &--negative{
      background: $color-red;
      border: 3px solid $color-red;
      opacity: .7;
    }
  }
  &__percentages {
    line-height: 1em;
    display: inline-block;
    padding-left: $spacing-s;
    font-size: $font-size-xs;
    &--positive{
      color: $color-positive-impact;
    }
    &--negative{
      color: $color-red;
    }
  }
}
