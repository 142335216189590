.search-filters-container{
  padding-bottom: 24px;
  .tags-filter,
  .product-search-box-input{
    border: 1px solid #E5E5E5 !important;
    border-radius: 4px;
    margin-right: 10px;
    height: 32px;
  }
  .product-search-box-input {
    width: 248px;
    position: relative;
    top: -1px;
  }
  .tags-filter{
    min-width: 75px;
  }
  .ant-select-suffix,
  .ant-input-prefix{
    color: #CCCCCC;
  }
  .product-filter-multiple-select *{
    cursor: pointer !important;
  }
  .ant-input-affix-wrapper-focused{
    border: none;
    box-shadow: none;
  }
  .ant-select-selector,
  .ant-input-affix-wrapper{
    border: none;
    color: #666666;
    .ant-select-selection-placeholder{
      color: #666666;
      opacity: 1;
    }
  }
}
