.product-reference {
  $root: &;

  margin-top: $spacing-sm;
  cursor: pointer;

  &__linked {
    display: flex;
    flex-wrap: nowrap;
    height: $spacing-l;

    &:hover {
      #{$root}__buttons {
        display: block
      }
    }
  }

  &__name {
    @extend .crop-text;
    flex-grow: 2
  }

  &__icon {
    padding: $spacing-xs;
    padding-left: 0;
    height: $ANT-spacing-24;

    svg {
      vertical-align: -1px;
    }

    &--link {
      color: $color-blue;
    }
  }

  &__actions {
    min-width: $spacing-4xl;
    display: flex;
  }

  &__buttons {
    display: none;
    min-width: $ANT-spacing-22;
    width: $ANT-spacing-22;
    min-height: $ANT-spacing-22;
    height: $ANT-spacing-22;
    padding: $spacing-xs;
  }
}

.ref-list .grey-light, .ref-description {
  color: $color-grey;
}
 
.product-reference-name {
  font-weight: bold;
}

.reference-modal-details {
  .product-reference-name {
    font-weight: 500;
    color: $color-grey-dark;
    font-size: $font-size-ssm;
  }
}
