.list-view {
    width: 100%;
    .ant-table {
        background: none;


        table {
            border-spacing: 0 8px;
        }

        th {
            background: none;
            background-color: transparent !important;
            color: #999999;
            padding-bottom: 0 !important;
            vertical-align: bottom !important;
            font-weight: 300;
            padding-top: 0 !important;
            &::before {
              background-color: transparent !important;
            }
        }

        td {
            background: $color-white;
            color: #333333 !important;

            span {
                color: #333333;
            }

            &:first-child {
                border-top-left-radius: $spacing-ss;
                border-bottom-left-radius: $spacing-ss;
            }

            &:last-child {
                border-top-right-radius: $spacing-ss;
                border-bottom-right-radius: $spacing-ss;
            }
        }

        th, td {
            border: none;
        }

        .ant-table-row:hover {
            cursor: pointer;

            border-radius: $spacing-ss;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);

            td.ant-table-cell-row-hover {
                background: $color-white !important;
            }
        }

        .list-view__selectedRow {
            td {
                border: 1px solid;
                border-left-width: 0;
                border-right-width: 0;
                border-color: $color-green !important;

                &:first-child {
                    border-left-width: 1px;
                }

                &:last-child {
                    border-right-width: 1px;
                }
            }
        }
    }


    &__sort {
        position: relative;
        top: $spacing-xs;
        left: $spacing-xs;
    }

    &__sortIcon {
        color: $color-grey-lighter;
        position: absolute;
        font-size: $spacing-sm;

        &--down {
            top: $spacing-ss;
        }

        &--up {
            top: 0;
        }
    }

    &__icon {
        float: left;
        width: 32px;
        height: 32px;
        background: #F3F4F6;
        color: #333333;
        border-radius: 5px;
        padding: 5px;
        margin-right: 16px;

        &--folder {
            color: #fff;
            background: #36536C;
        }
        &--product {
          color: #0C7DD0;
          background: #F3F9FD;
        }
        &--object {
          color: #36536C;
        }
        &--lifecycle {
          color: #8B6E89;
          background: #F9F8F9;
        }
    }

    &__name {
        float: left;
        width: calc(100% - 130px);
        padding: 5px 0;
    }

    &__actions {
        float: right;
        width: fit-content;
        padding: 7px 0 0 10px;
    }

    &__action {
        cursor: pointer;
        margin: 0 $spacing-xs;
        color: #CCCCCC;
        padding: 0;
        min-width: none;
        min-height: none;
        height: auto;
        width: auto;
        border: none;
        background: none;
        box-shadow: none;

        &:focus:not(.list-view__action--selected) {
            color: #0C7DD0 !important;
        }
        .ant-dropdown-open {
            color: $color-green;
        }

        &--selected,
        &:hover {
            color: #0C7DD0 !important;
            background: none;
        }

        &--more {
            vertical-align: -3px;
            font-size: $spacing-ml;
        }
    }

    &__actionItem {
        display: block;
        width: 100%;
    }

    &__menuOverlay {
        .ant-dropdown-menu-item {
            &:hover {
                color: $color-green;
                background-color: $color-white;
            }
        }
    }

    &__productIcon {
        float: left;
        width: $spacing-ml;
    }

    &__productName {
        width: calc(100% - #{$spacing-ml});
    }

    &__folderIcon {
      background-color: $color-blue-dark-v1;
      color: white;
      font-size: $font-size-ms;
      padding: $spacing-xs;
      &:hover{
        color: white;
      }
      border-radius: $border-radius;
      float: left;
      margin-right: $spacing-ss;
    }

}
