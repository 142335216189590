.loading-page{
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	.title {
		align-items: center;
		display: flex;
		margin-bottom: $spacing-m;
		.logo {
      height: $spacing-xl;
      margin-right: $spacing-sm;
		}
  }
  .loading{
    > i {
      margin-top: $spacing-ml;
      display: block;
      font-size: $font-size-ml;
    }
  }
}

.browsers-page{
  .browser-list{
    padding-top: $grid-padding;
  }
  .browser-item:not(:last-child){
    border-right: 1px solid $color-divider;
  }
  .browser-name{
    display: block;
    padding-top: $grid-padding;
  }
  .footer {
    margin-top: $grid-padding-x4;
    opacity: 0.8;
    .ecochain-logo {
      background-color: $color-background-light;
      margin-right: $ANT-spacing-4;
    }
    .logo-text {
      font-family: 'Gilroy ExtraBold', sans-serif;
    }
  }
}
