.colored-tag {
  display: inline-block;
  padding: 0 $spacing-xxs;
  cursor: default;
  font-size: $font-size-xxxss;
  border-radius: 3px;
  line-height: 1.4;
  .close-colored-tag-icon {
    margin-left: $spacing-xs;
    font-size: $font-size-xxxs;
    cursor: pointer;
  }
}