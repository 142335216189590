.model .workspace > .inventory .item,
.model .workspace > .process .input-list .item,
.model .workspace > .process .output-list .item {
    align-items: center;
    border: 1px solid transparent;
    border-radius: $border-radius;
    cursor: pointer;
    margin-bottom: $spacing-s;
    min-height: $spacing-5xl;
    padding: 0px $spacing-2xl;
    position: relative;
    width: 100%;

    transition: background-color $default-animation-speed, border $default-animation-speed;
    @include breakpoint('laptop'){
        padding: 0 $spacing-l;
    }
    @extend .crop-text;
    &.edit .remove-item{
        display: none;
    }
    .remove-item{
        opacity: 0;
        cursor: pointer;
        font-size: $font-size-s;
        padding: 0 $ANT-spacing-4 $ANT-spacing-4;
        position: absolute;
        right: 0;
        text-align: center;
        transition: background-color $default-animation-speed, opacity $default-animation-speed;

    }
    &:hover {
      .remove-item {
          opacity: 1;
      }
      &.connect-new .remove-item{
        display: none;
      }
    }
    &.on{
        border: 1px solid $color-steel;
        .remove-item {
            opacity: 1;
        }
    }
    &:hover > .details-icons .details-highlight,
    &.on > .details-icons .details-highlight {
        display: none;
        &.on{
            display: block;
        }
    }
    .details-icons {
        .details-highlight{
            display: none;
            position: absolute;
        }
    }
    .impact-label{
        position: absolute;
        top: -10px;
        &.right{
            right: -4px;
        }
        &.left{
            left: $spacing-xs;
        }
    }
    &.output {
        .remove-item {
            right: auto;
            left: 0;
        }
    }
    &.edit {
        align-items: center;
        border: solid 1px transparent;
        height: $spacing-5xl;
        & > input {
            background-color: transparent;
            &.bare {
                border: none;
                box-shadow: none;
                padding: 0px;
            }
        }
        .ant-select-auto-complete.ant-select {
            .ant-input:focus,
            .ant-input:hover {
                border-color: transparent;
            }
            .ant-select-selection--single {
                background-color: transparent;
            }
        }
        .autocomplete {
            width: 100%;
            input {
                border: none;
                padding: 0px;
            }
        }
    }
    .name-container{
      width: 100%;
    }
    > .name {
      &.left {
          text-align: left;
      }
      &.right {
          text-align: right;
      }
    }
}
