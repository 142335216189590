// GLOBAL
$color-green:         #56ca19;
$color-green-light:   #32cd321a;
$color-navy:          #072b49;
$color-orange:        #ff6010;
$color-red:           #f22f2c;
$color-tertiary:      #ff6010;
$color-yellow:        #faad14;
$color-yellow-light:  #E0E42B;

$color-navy-dark:     #042036;

$color-grey-dark:     #6b6b6b;
$color-grey-dark-1:   #424242;
$color-grey:          #9b9b9b;
$color-grey-light:    #d3d3d3;
$color-grey-lighter:  #d9d9d9;
$color-grey-lighter-1:#e2e2e2;
$color-grey-lightest: #eeeeee;
$color-grey-bright:   #f8f8f8;
$color-grey-bright-1: #f4f4f4;
$color-white:         #ffffff;

$color-steel-darker:  #3a3a42;
$color-steel-dark:    #929599;
$color-steel:         #98a6b2;
$color-steel-light:   #d4d9de;
$color-steel-lighter: #e9edf0;//#e4e9ee;
$color-steel-bright:  #eef0f2;//#e8eef4;

$color-blue:          #1890ff;
$color-blue-dark:     #0060AE;
$color-blue-v1:       #0077D8; // v1.0 blue
$color-blue-dark-v1:    #516B80;
$color-primary-500: #0C7DD0;

// LAYOUT
$color-background: $color-grey-bright;
$color-background-dark: $color-grey-lightest;
$color-background-light: $color-white;

// DIVIDER
$color-divider:$color-grey-lighter;
$color-divider-dark: $color-grey-dark;

// TEXT
$color-text: $color-steel-darker;
$color-text-primary: $color-green;
$color-text-grey:$color-grey;
$color-text-grey-dark: $color-grey-dark;
$color-text-grey-light: $color-grey-light;
$color-text-inverse: $color-background-light;
$color-text-title: $color-navy;
$color-text-sidebar: $color-yellow-light;

// TABLE
$color-table-header:$color-steel-bright;
$color-table-border: $color-steel-light;

// IMPACT
$color-negative-impact: $color-blue-dark;
$color-positive-impact: $color-green;
$gradient-negative-to-left: linear-gradient(to left, rgba($color-negative-impact, 0.8), rgba($color-negative-impact, 0.2));
$gradient-negative-to-right: linear-gradient(to right, rgba($color-negative-impact, 0.8), rgba($color-negative-impact, 0.2));
$gradient-positive-to-left: linear-gradient(to left, rgba($color-positive-impact, 1), rgba($color-positive-impact, 0.3));
$gradient-positive-to-right: linear-gradient(to right, rgba($color-positive-impact, 1), rgba($color-positive-impact, 0.3));

// PHASES
$color-phase-1: $color-green;
$color-phase-2: $color-navy;
$color-phase-3: $color-orange;
$color-phase-4: $color-red;

// STATUS
$color-status-ok: $color-green;
$color-status-error:  $color-red;
$color-status-warn: $color-orange;


// CLASSES
.primary-fill { fill: $color-green; }
.secondary-color { color: $color-navy; }
.secondary-fill { fill: $color-navy; }
.tertiary-color { color: $color-orange; }
.default-loader-color { color: $color-text; }

// Text color
.text { color: $color-text; }
.text-grey { color: $color-text-grey; }
.text-grey-dark { color: $color-text-grey-dark; }
.text-grey-light { color: $color-text-grey-light; }
.text-title { color: $color-text-title; }
.text-primary { color: $color-text-primary; }

// Phases color
.color-phase-1 sup { background-color: $color-green; }
.color-phase-2 sup { background-color: $color-orange; }

