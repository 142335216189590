.reference-properties {
  .hidden{
    visibility: hidden;
  }
  .product-reference-properties-list {
    .property-item-header {
      border: none;
      margin-bottom: $spacing-s;

      &--empty {
        text-align: center;

        .ant-collapse-header {
          color: $color-grey;
        }
      }

      & > .ant-collapse-header{
        display: block;
        font-weight: normal;
        width: 100%;
        .ant-collapse-content-box{
          padding: 0;
        }
        &:first-child{
          padding: 0;
        }

        .property-row {
          height: $spacing-ml;
        }
        
        .property-for,
        .property-name,
        .property-unit,
        .property-amount{
          @extend .crop-text;
        }

        .property-for {
          font-size: $font-size-xxs;
          color: $color-grey;
        }

        .property-actions
        {
          opacity: 0;
          text-align: right;
          button{
            padding: 0 0 0 $spacing-xs;
            &.icon{
              padding: 0 $spacing-s 0;
            }
          }

        }
      }
      .ant-collapse-item-active{
        .property-actions{
          opacity: 1;
          .edit-property{
            color: $color-green;
          }
        }
      }
      &:hover,
      &:focus{
        .property-actions{
          opacity: 1;
        }
      }
    }
  }

  &__formHeader {
    @extend .crop-text;
    color: $color-grey;
  }

  .product-property-action {
    margin-top: $spacing-m;
  }
}
