.impact-unit-props {
  color: $color-navy;
  font-style: italic;
  font-size: 80%;
}

.phase-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: $spacing-s;
  .add-product-btn{
    padding-left: $spacing-s;
    padding-top: 8px;
  }
  .impact {
    color: $color-navy;
    font-style: italic;
    font-size: 80%;
  }
}

.inventory-padding-left {
  padding-left: $spacing-s;
}

.inventory-padding-right {
  padding-right: $spacing-s;
}

.inventory-tree {
  width: 100%;
  background: none;
  .ant-tree-switcher{
    padding: 0 $spacing-sm;
    width: auto;
    background-color: $color-steel-bright;
    border-radius: $border-radius 0 0 $border-radius;
    align-items: center;
    display: flex;
  }

  .inventory-tree-item-wrapper {
    width: 100%;
  }

  .inventory-item {
    color: $color-navy-dark;
    font-weight: normal;
    font-size: $font-size-s;
    width: 100%;
    padding: 0 0 $spacing-s 0;
    transition: none;

    &.inventory-root-item {
      font-weight: 500;
      .impact {
        font-weight: normal;
      }
      .actions {
        opacity: 0.3;
        &:hover {
          opacity: 1
        }
      }

      .phase-container {
        overflow: hidden;
        width: 0;
        transition: width 500ms;
        &.on {
          min-width: $spacing-xl;
        }

        .phase-badge {
          background-color: $color-steel-dark;
          width: $spacing-ml;
          height: $spacing-ml;
          overflow: hidden;
          text-align: center;
          border-radius: 50%;
          color: white;
          font-size: $font-size-xxs;
          margin-left: $spacing-sm;
          font-weight: normal;
        }
      }
      .tree-item-container {
        .actions {
          align-items: center;
          visibility: visible;
        }
      }
    }

    .ant-tree-node-content-wrapper {
      width: calc(100% - #{$spacing-3xl});
      background-color: $color-steel-bright;
      border-radius: 0 $border-radius $border-radius 0;
      padding: 0;
      transition: none;


      &:hover,
      &.ant-tree-node-selected:hover,
      &.ant-tree-node-selected:not(:hover) {
        background-color: $color-steel-bright;
      }

      .ant-tree-title {
        display: flex;
        width: 100%;
        .title {
          flex-grow: 9;
        }

        .impact {
          flex-grow: 0;
          text-align: right;
          @extend .impact-unit-props;
        }
        .bars{
          padding-left: $spacing-ml;
          flex-shrink: 0;
          flex-grow: 0;
          .impact-bar-container{
            width: $impact-bars-width;
          }
        }
      }
    }
    .ant-tree-switcher-line-icon{
      width: $spacing-ml;
    }
    .tree-switcher-icon {
      font-size: $font-size-m;
      color: $color-grey;
    }
    .ant-tree-indent-unit::before {
      border: none;
    }
  }
}


.positiveBarInTree {
  height: $spacing-m;
  background: linear-gradient(to right, rgba($color-positive-impact, 0.8), rgba($color-positive-impact, 0.24));
}

.negativeBarInTree {
  @extend .positiveBarInTree;
  background: linear-gradient(to right, rgba($color-negative-impact, 0.7), rgba($color-negative-impact, 0.1));
}

.inventory-item-impact-status {
  text-align: right;
}

.inventory-tree-header-labels {
  padding: $spacing-s;
  color: $color-navy;
  opacity: 0.5;
  font-size: $font-size-s;
  .inventory-tree-unit-label {
    text-align: right;
    &.impact-bars-margin {
      margin-right: $spacing-2xl;
    }
  }
}

.total-impact-percentage {
  padding-left: $spacing-sm;
}
