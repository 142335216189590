form.new-account {
  input {
    width: 100%;
  }
  .switch-account {
    align-items: center;
    display: flex;
    margin-bottom: $spacing-l;
    > button {
      margin-right: $spacing-sm;
    }
  }
}

.page-header {
  align-items: center;
  display: flex;
  margin-bottom: $spacing-ml;
  .title > h1 {
    margin: 0px;
  }
  > i {
    cursor: pointer;
    font-size: $font-size-ml;
    position: relative;
  }
  > .title {
    flex-grow: 1;
    margin: 0px;
  }
  .rename-workspace {
    display: flex;
    flex-grow: 1;
    margin-right: $spacing-ml;
    > div:first-child {
      flex-grow: 1;
    }
    input {
      flex-grow: 1;
      font-size: $font-size-l;
      padding: $spacing-ml;
    }
    button {
      margin-left: $spacing-sm;
      height: $spacing-3xl;
      i {
        font-size: $font-size-m;
      }
    }
  }
}

.permission-popup {
  min-width: 750px;
  .add-owner {
    display: flex;
    > div:first-child {
      flex-grow: 1;
      margin-right: $spacing-sm;
    }
  }
  .policies {
    width: 100%;
  }
}

.modal-dialog {
  padding-bottom: $spacing-l;
  .ant-modal-body {
    padding: 0 $spacing-5xl;
    h1 {
      padding-top: $spacing-5xl;
      font-size: $font-modal-dialog-title;
      font-family: $font-family-default !important;
      font-weight: 500;
      color: $color-text-grey-dark;
    }
    p,
    .content {
      font-size: $font-size-s;
      color: $color-text-grey;
      line-height: 1.6em;
    }
  }
  .ant-modal-footer {
    padding: $spacing-xl $spacing-5xl $spacing-5xl $spacing-5xl;
    border: none;
  }

  &.modal-with-banner {
    .ant-modal-header {
      padding: 0;
      background-color: $color-navy;
      .modal-blue-banner {
        text-align: center;
        padding: $modal-banner-padding 0;
      }
    }
    .ant-modal-body {
      h1 {
        font-size: $font-header-size;
      }
    }
  }
}

.single-tree{
  flex: 0 0 calc(100% - #{$details-panel-inventory-gap});
}
.double-tree{
  flex: 0.5;
}
