.page-header-wrapper {
  background-color: #fff;
  border-bottom: 1px solid $color-divider;
  padding: 0 $grid-padding;
  & > div {
    // fix for flex grids contains text with white-space: nowrap
    min-width: 0;
  }
  .back-navigation-button {
      cursor: pointer;
      position: absolute;
      top: -$ANT-spacing-4;
      padding: 0;
      line-height: 0;
      color: $color-text-grey-light;
      font-size: $font-size-page-header-back;
      text-transform: uppercase;
      font-weight: 600;
      width: 100%;
      span {
        display: block;
        float: left;
      }
      .back-arrow{
        font-size: 1.5em;
        position: relative;
        top: 0.2em;
      }
      .back-text {
        @extend .crop-text;
        width: calc(100% - #{$spacing-5xl});
        text-align: left;
        line-height: 23px;
      }
  }
  .page-header-title {
    padding: $ANT-spacing-16 0;
    width: 100%;
    @extend .crop-text;
    margin: 0;
  }
  .actions{
    button{
      margin-left: $grid-padding;
    }
    .add-new-page-header-button {
        &:hover {
            color: white;
        }
        color: white;
        background-color: $color-green;
    }
  }
}
.page-header-dropdown{
  & > ul {
    padding-bottom: 0;
    & > li{
      border-bottom: 1px solid $color-divider;
      padding: $spacing-sm;
      &:last-child{
        border-bottom: none;
      }
      .icon:first-child{
        display: inline-block;
        font-size: $font-size-ml;
        color: $color-green;
        padding: $spacing-sm;
        vertical-align: top;
      }
      .text{
        display: inline-block;
        .title{
          display: block;
          color: $color-navy;

          &--import {
            font-weight: bold;
          }
        }
        .description{
          display: block;
          color: $color-steel;
          font-size: $font-size-xs;
        }
      }
      &.disabled {
        opacity: 0.5;
        &:hover{
          background: none;
        }
      }
    }
  }
}

.v1 {
  &.page-header-wrapper {
    background-color: transparent;
    border-bottom: none;
    padding: 0 2.5rem;
  }
  .back-navigation-button {
    position: relative;
    color: #666666; // Main/Grey-500
    font-size: 1rem;
    font-weight: 500;
    text-transform:none;
    top: 1.5rem;
    .back-arrow{
      font-size: 0.875rem;
      top: 0.3125rem;
    }
  }
  .page-header-title {
    padding: 2.5rem 0;
    font-size: 2rem;
    font-weight: 500;
  }
  .actions{
    button {
      margin-left: 24px;
      &.product-import-button {
        font-size: 20px;
        padding: 0;
        width: 40px;
        overflow-y: hidden;
        svg{
          position: relative;
        }
      }
      &.add-new-page-header-button {
        .anticon-plus{
          display: none;
        }
      }
    }
  }
}
