$breakpoints: (
  'laptop-sm':  1366px,
  'laptop':     1440px,
  'desktop':    1680px,
  'desktop-md': 1920px,
  'desktop-lg': 2560px
) !default;

/*  Mixin to manage responsive breakpoints
 * @param {String} $breakpoint - Breakpoint name
 * @require $breakpoints
 */
@mixin breakpoint($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (max-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}