.payment-details{
  .payment-method{
    font-size: $font-size-sm;
    font-weight: normal;
    .expiration{
      font-size: $font-size-s;
      color: $color-text-grey;
      padding-left: 0.5em;
    }
  }
}
