.custom_impact-edit, .custom_impact_exist-message {
  cursor: pointer;
  &:hover {
    .custom_impact-edit {
      visibility: visible;
    }
  }
}

.custom_impact_exist-message {
  margin: 0;
  padding-top: $spacing-m;
}

.custom_impact-edit_wrapper {
  float: right;
}

.custom_impact-edit {
  visibility: hidden;
}

.custom_impact-low-prio {
  color: $color-grey;
}
