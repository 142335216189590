.impactSelectorDialog{

  &--scrolled {

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: $spacing-11xl;
      left: 0;
      width: 100%;
      height: $spacing-sm;
      z-index: 2;
      box-shadow: inset 0px $spacing-xxs $spacing-xxs rgba(0, 0, 0, 0.1);
    }
  }

  &__title{
    @extend .crop-text;
    margin: 0;
    line-height: 1;
    color: $color-text-title;
    padding: $spacing-xl 0 $spacing-l;
    font-size: $font-size-ml;
  }

  &__product {
    color: green;
  }

  &__tabs{
    [role="tablist"]{
      padding: 0;
    }

    .ant-tabs-content-holder {
      max-width: 1440px;
    }

    .ant-tabs-nav-wrap {
      border-bottom: 1px solid $color-grey-light;
    }

    .ant-tabs-tab-btn {
      color: $color-grey !important;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $color-grey-dark !important;
      font-weight: 500 !important;
    }

    .ant-tabs-ink-bar {
      background-color: #555555;
    }
  }

  &__tab {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 0 $spacing-ml;
    overflow: hidden;
  }

  &__icon {
    font-size: $font-size-sm;
    padding-right: $spacing-xxs;
  }

  &__label {
    padding-left: $spacing-xxs;
  }

  .ant-modal-body,
  .ant-modal-header {
    padding: 0 $spacing-3xl;
  }

  .ant-modal-body  {
    height: calc(100vh - #{$spacing-13xl});
    overflow: auto;
  }

  .ant-tabs-nav {
    margin-bottom: $spacing-sm;
  }

  .ant-modal-header,
  .ant-tabs-top > .ant-tabs-nav::before {
    border: none;
  }
}

