.impact-methods-dropdown,
.impact-method-set-dropdown,
.impact-methods-dropdown,
.impact-normalization-dropdown,
.impact-category-dropdown,
.impact-categories-dropdown,
.product-property-dropdown,
.lifecycle-product-dropdown {
  left: auto !important;
  right: $spacing-ml;
}
