.coupon-container{

  input{
    width: $coupon-input-width;
    font-size: $font-size-ms;
  }
  .coupon-applied{
  font-size: $font-size-sm;
    font-weight: normal;
  }
  .expiration{
    font-size: $font-size-s;
    color: $color-text-grey;
    padding-left: 0.5em;
  }
}
