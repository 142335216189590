.card-list-view {

  .selected {
    color: $color-blue;
  }

  .card-container {
    margin-bottom: $grid-padding;
  }

  .item-wrapper {
    padding: $ANT-spacing-8 0;
  }

  .empty-list {
    margin: 0;
  }

  &__action {
    float: right;
    width: fit-content;
    margin-left: 16px;

    button {
      border-radius: $border-radius !important;
      background-color: transparent;
      color: #666666 !important;
      border-color: $color-grey-lighter !important;
      padding-top: 5px;
      &:hover {
        transition: none;
        background-color: #F3F9FD;
        color: #0C7DD0 !important;
        border-color: #0C7DD0 !important;
      }
    }
  }
}

.tags-list {
  min-height: $ANT-spacing-22;

  &--right {
    text-align: right;

    .colored-tag {
      margin: 0 0 $spacing-xs $spacing-ss;
    }
  }

  &--left {
    text-align: left;

    .colored-tag {
      margin: 0 $spacing-ss $spacing-xs 0;
    }
  }
}
