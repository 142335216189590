.nodeGroup {
  font-family: $font-family-tertiary;
  font-size: $font-size-ml;
  text-anchor: middle;
  fill: white;
  stroke: $color-navy;
  stroke-width: 0.5;
  cursor: pointer;
}
.mainLabel {
  pointer-events: none;
  font-family: $font-family-primary;
  font-size: $font-size-ms;
  text-anchor: middle;
  fill: $color-navy;
}
.mainLabelSmall {
  pointer-events: none;
  font-family: $font-family-tertiary;
  font-size: $font-size-s;
  text-anchor: middle;
  fill: $color-navy;
}

.innerLabel {
  pointer-events: none;
  font-family: $font-family-tertiary;
  font-size: $font-size-s;
  text-anchor: middle;
  fill: $color-navy;
}

.innerLabel, .outerLabel text {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg text::selection {
  background: none;
}

.outerLabel {
  pointer-events: none;
  position: absolute;
  font-family: $font-family-tertiary;
  font-size: $font-size-s;
  text-anchor: middle;
  fill: $color-navy;
  stroke: none;
}

.labelImpact {
  text-align: center;
  vertical-align: middle;
  // text-anchor: middle;
  font-family: $font-family-tertiary;
  font-size: $font-size-xs;
  fill: $color-navy;
  stroke: none;
}

.segment {
  opacity: 0.7;
  stroke: white;
  stroke-width: 1;
}

.tooltip {
  font-family: $font-family-tertiary;
  font-size: $font-size-xs;
  position: absolute;
  height: auto;
  padding: $spacing-s;
  background-color: white;
  border-radius: $border-radius;
  box-shadow: $spacing-s $spacing-s $spacing-sm grey;
  pointer-events: none;
  display: table-cell;
}

.sunburst-container{
  padding-top: $spacing-ml;
  border: 1px solid $color-grey-lighter;
  background-color: white;
  margin: $spacing-sm;
  .switches-container{
    position: absolute;
    top: 0;
    right: 0;
  }
}
