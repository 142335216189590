.search-reference {
  padding: 0;
  margin: 0 !important;

  &__query {
    padding: $spacing-ml 0 !important;
  }

  &__result {
    padding: $spacing-sm $ANT-spacing-16 0 0 !important;
  }

  &__sidebar {
    overflow-x: hidden;
    padding: $spacing-sm 0 0 $ANT-spacing-16 !important;
  }

  &__input {
    width: 600px;
    height: $spacing-2xl;
    border-radius: $spacing-xxs;

    svg {
      color: $color-grey-light;
    }

    &:hover,
    &:focus {
      outline:none;
      border: 1px solid $color-green;
      box-shadow: 0 0 $spacing-s  $color-green;
    }
  }

  &__info {
    padding: $spacing-sm 0;
  }

  &__limit {
    padding: 0;
    margin: 0;

    color: $color-red;
    font-size: $spacing-sm;
  }

  &__tips {
    .ant-divider-horizontal{
        margin: $spacing-m 0;
    }
  }

  &__suggestions {
    font-size: $font-size-sm;
    margin-top: $spacing-sm;
    color: $color-text-grey-light;
  }

  &__tag {
    border: none;
    border-radius: $border-radius;
    background-color: $color-grey-lightest;
    color: $color-grey;
    font-size: $font-size-xs;
    cursor: pointer;
    height: $ANT-spacing-24;
    padding: $spacing-xs $spacing-xxs;
  }

}


