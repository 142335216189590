.inventory-name-label{
  padding: $ANT-spacing-4;
  max-width: 100%;
  position: absolute;
  white-space: nowrap;
  &__text{
    width: 100%;
    &--with-tag{
      width: calc(100% - 68px);
    }
  }
  &__tag{
    min-width: 64px;
    text-align: center;
    border: none;
    font-weight: $thin;
    color: $color-text-grey;
    position: relative;
    margin-left: $ANT-spacing-4;
    background-color: $color-grey-lightest;
    border-radius: $border-radius;
    font-size: $font-size-xxs;
    &--primary{
      background-color: $color-blue-v1;
      color: $color-white;
    }
  }
  &__container{
    padding-bottom: $ANT-spacing-32;
    cursor: pointer;
    border-radius: $border-radius;
    &.active,
    &:hover{
      background-color: $color-steel-lighter;
    }
  }
}
