@mixin card-list-style {
  padding: 0;
  cursor: pointer;
  border-radius: 6px;
  height: 152px;
  border: none;

  &:hover {
    box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.05);
  }
  &--selected {
    border: 1px solid $color-green;
    &:hover {
      box-shadow: none;
    }
  }
  .ant-card-body{
    padding: 0;
    height: 100%;
  }

  &__name {
    float: left;
    width: 42px;
    padding-right: 10px;
  }

  &__tags {
    float: left;
    width: calc(100% - 42px);
    height: 100%;
    overflow: hidden;

    .colored-tag {
      float: right;
      margin-left: 4px;
      margin-bottom: 4px;
    }
  }

  &__label {
    max-height: 3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__header {
    display: flex;
    height: 53px;
    padding: $spacing-m;
    padding-bottom: $spacing-ss;
  }

  &__iconHeader {
    float: left;
    width: 32px;
    height: 32px;
    background: #F3F4F6;
    color: #333333;
    border-radius: 5px;
    padding: 6px;
    margin-right: 16px;

    &--folder {
        color: #fff;
        background: #36536C;
    }
    &--product {
      color: #0C7DD0;
      background: #F3F9FD;
    }
    &--object {
      color: #36536C;
    }
    &--lifecycle {
      color: #8B6E89;
      background: #F9F8F9;
    }
  }

  &__content {
    padding: $spacing-ss $spacing-m;
    height: calc(100% - 86px);
  }

  &__footer{
    padding: $spacing-s $spacing-m;
    display: flex;
  }

  &__title {
    float: left;
    width: calc(100% - 20px);
    height: $spacing-ml;
    padding-top: 4px;

    h3 {
      font-family: $font-family-tertiary;
      font-size: $font-size-sm;
      color: $color-navy-dark;
      font-weight: $thin;
      @extend .crop-text;
    }

    .displayed-value {
      width: 100%;
    }
  }

  &__menu {
    width: $spacing-ml;
    height: $spacing-ml;
    font-size: $spacing-ml;
    font-weight: bold;

    .ant-dropdown-open {
      color: $color-text-primary;
    }
  }

  &__menuItem {
    display: block;
    width: 100%;

    &--delete {
      color: var(--red-500);
      &:hover {
        color: var(--primary-500) !important;
      }
    }
  }

  &__menuOverlay {
    .ant-dropdown-menu-item {
      &:hover {
        color: var(--primary-500) !important;
        background-color: $color-white;
      }
    }
  }

  &__description{
    font-size: $font-size-xs;
    color: $color-text-grey;
    overflow: hidden;
    padding: $spacing-xxs 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: $ANT-spacing-44;
  }

  &__product{
    font-size: $font-size-xs;
    @extend .crop-text;
    min-height: $ANT-spacing-22;
    margin: 0;
    padding: 0;
  }

  &__action {
    .ant-btn {
      margin: 0 $spacing-xs;
    }
  }

  &__date {
    margin: 0;
    font-size: $font-size-xs;
    color: #999;
    span {
      display: block;
      float: left;
    }
  }

  &__sync {
    margin-right: $spacing-s;
    font-size: $font-size-s;
  }

  &__icon {
    vertical-align: top;
    padding: 0;
    width: auto;
    height: auto;
    min-width: 0;
    min-height: 0;
    font-size: $font-size-ssm;
    color: #999 !important;

    &:focus:not(.list-view__action--selected) {
      color: $color-steel-darker !important;
    }

    &:hover,
    &--selected,
    &--selected *{
      color: var(--primary-500) !important;
    }
  }
}
.card-list-item {
  @include card-list-style;
}
.card-list-folder {
  @include card-list-style;

  &__content{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    height: calc(100% - 38px);
    text-align: center;
  }

  &__dropdown{
    float: right;
  }

  &__iconWrap {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    flex-grow: 2;
    padding-top: 10px;
  }

  &__iconFolder {
    width: 48px;
    height: 48px;

    margin: 0 auto;
    padding: $ANT-spacing-8 0;
    border-radius: $border-radius;
    background-color: #36536C;
    color: $color-white;

    &:hover,
    svg:hover {
      color: $color-white;
    }
  }
}
