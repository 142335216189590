
.model .workspace > .process .process-col .allocation {
    background-color: $color-steel-lighter;
    border: solid 1px $color-steel;
    border-radius: $border-radius;
    margin-bottom: $spacing-m;
    padding: $spacing-sm;
    .allocation-item, .new-allocation {
        align-items: center;
        display: flex;
        margin-top: $spacing-sm;
        .action, .unit {
            width: 5%;
        }
        .amount {
            width: 10%;
        }
        .amount, .input, .output, .unit {
            margin-right: $spacing-sm;
            &.bare {
                border: none;
                box-shadow: none;
                padding: 0px;
            }
        }
        .input, .output {
            width: 40%;
        }
    }
}

.process-list-actions {
  display: flex;
  button {
    border-color: $color-steel-light;
  }
}
