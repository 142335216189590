.view-type-selector {

  label{
    background-color: transparent;
    padding: 2px 7px;
    color: #666666;
    &:first-child{
      border-radius: 4px 0 0 4px!important;
    }
    &:last-child{
      border-radius: 0 4px 4px 0!important;
    }
    &:focus
    span:focus {
      box-shadow: none !important;
      outline: none !important;
    }
    &:hover{
      color: #0C7DD0;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #666666;
    background: transparent;
  }
  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    border-color: #666666;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
    background-color: #666666;
  }

  .ant-radio-button-wrapper:not([class*=' ant-radio-button-wrapper-checked']).ant-radio-button-wrapper:first-child:hover,
  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):first-child:hover,
  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):hover{
    border-color: #0C7DD0;
    background-color: #F3F9FD;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
    box-shadow: none;
  }
}
