//@import '../colors';
//@import '../typography';
::selection {
    background: $color-navy;
    color: $color-text-inverse;
}
html, body {
    height: 100%;
    width: 100vw;
	overflow-x: hidden;
}
body {
    background-color: $color-background;
    font-family: $font-family-tertiary;
    color: $color-text;
    font-size: $font-size-s;
}
*::-webkit-scrollbar {
  width: $ANT-spacing-8;
}
*::-webkit-scrollbar-track {
  background: $color-background;
}
*::-webkit-scrollbar-thumb {
  background-color: $color-grey-lighter;
  border-radius: $border-radius;
}

#root, .ecochain-ui {
    height: 100vh;
    margin: 0px;
}

h1, h2, h3, h4 {
    font-family: $font-family-primary;
    color: $color-navy;
}

h1 {
    font-size: $font-size-l;
    margin-bottom: $spacing-m;
}

a, button, input {
    font-family: $font-family-tertiary;
    &:hover, &:focus, &:active {
        outline: 0;
    }
}

a, a:visited, a:active {
    color: $color-navy;
    text-decoration: underline;
    transition: color $default-animation-speed;
}

.display-none {
    display: none;
}

.loading {
    position: relative;
    &.center {
        width: 100%;
    }
    &.on > i {
        display: block;
    }
    > i {
        display: none;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
    }
    > .disabled {
        opacity: .5;
    }
}

.loader-wrapper > div:first-of-type {
  @include z-index(global-loader);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.full-parent-height {
  height: 100%;
}

.clickable {
    cursor: pointer;
    &:hover {
        color: $color-green;
    }
}
