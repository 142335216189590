.billing{
  .card-item{
    margin: $spacing-sm 0;
    border-radius: $border-radius;
  }
}

.plan-details{
  .plan-item{
    padding:0;
    .plan-title{
      padding: 1em 0;
    }
    .description{
      height: auto;
    }
  }
}
