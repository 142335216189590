.account-settings {
  flex-grow: 1;
  padding: $grid-padding;
  .settings-card{
    border-radius: $border-radius;
  }
  .user-management{
    .field-container{
      padding-bottom: 0;
      .ant-form-item-explain{
        position: absolute;
        top: 2.5em;
      }
    }
  }
  h1, h2 {
    margin-bottom: $ANT-spacing-24;
  }
  .table-container{
    .ant-table-title{
      text-align: right;
    }
    table {
      th{
        background: none;
      }
      .action {
        white-space: nowrap;
        button{
          margin: 0 $ANT-spacing-8;
        }
      }
    }
  }

  td.api-client-secret {
    white-space: nowrap;
    button.ant-switch {
      width: 64px;
    }

    .hidden-text {
      font-family: monospace;
    }
  }
  .v2api-container{
    .sub-title{
      margin-bottom: $ANT-spacing-16;
    }


  }
  .invited-user-icon {
    font-size: $font-size-ml;
    color: $color-navy;
  }
}


