/// Padding of a button, must be defined with a shorthand for vertical and horizontal values e.g. ".5rem .5rem"
/// @group button
$buttonPadding: 0.656rem 1rem;

/// Width of a button having an icon with no label
/// @group button
$buttonIconOnlyWidth: 1.75rem;

/// Padding of a button having an icon with no label
/// @group button
$buttonIconOnlyPadding: .5rem 0;

/// Background of a button
/// @group button
$buttonBg: var(--primary-500);

/// Text color of a button
/// @group button
$buttonTextColor: var(--surface-a);

/// Border of a button
/// @group button
$buttonBorder: 1px solid var(--primary-500);

/// Background of a button in hover state
/// @group button
$buttonHoverBg: var(--primary-300);

/// Text color of a button in hover state
/// @group button
$buttonTextHoverColor: var(--primary-50);

/// Border color of a button in hover state
/// @group button
$buttonHoverBorderColor: var(--primary-300);

/// Background of a button in pressed state
/// @group button
$buttonActiveBg: var(--primary-600);

/// Color of a button in pressed state
/// @group button
$buttonTextActiveColor: var(--primary-100);

/// Border color of a button in pressed state
/// @group button
$buttonActiveBorderColor: var(--primary-600);

/// Shadow of a raised button
/// @group button
$raisedButtonShadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

/// Border radius of a rounded button
/// @group button
$roundedButtonBorderRadius: 2rem;

/// Alpha level of a text button background in hover state
/// @group button
$textButtonHoverBgOpacity:.04;

/// Alpha level of a text button background in active state
/// @group button
$textButtonActiveBgOpacity:.16;

/// Border style of a outlined button
/// @group button
$outlinedButtonBorder:1px solid;

/// Text color of a plain text button
/// @group button
$plainButtonTextColor: var(--gray-500);

/// Border of a plain button
/// @group button
$plainButtonBorder: var(--gray-100);

/// Background color of a plain text button in hover state
/// @group button
$plainButtonHoverBgColor: var(--primary-500);

/// Background color of a plain text button in active state
/// @group button
$plainButtonActiveBgColor: var(--primary-700);

/// Background of a secondary button
/// @group button
$secondaryButtonBg: $secondaryColor;

/// Text color of a secondary button
/// @group button
$secondaryButtonTextColor: var(--surface-a);

/// Border of a secondary button
/// @group button
$secondaryButtonBorder: 1px solid $secondaryColor;

/// Background of a secondary button in hover state
/// @group button
$secondaryButtonHoverBg: $secondaryDarkColor;

/// Text color of a secondary button in hover state
/// @group button
$secondaryButtonTextHoverColor: var(--surface-a);;

/// Border color of a secondary button in hover state
/// @group button
$secondaryButtonHoverBorderColor: $secondaryDarkColor;

/// Background of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBg: $secondaryDarkColor;

/// Text color of a secondary button in pressed state
/// @group button
$secondaryButtonTextActiveColor: var(--surface-a);

/// Border color of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBorderColor: $secondaryDarkColor;

/// Box shadow of a secondary button in focused state
/// @group button
$secondaryButtonFocusShadow: none;

/// Background of an info button
/// @group button
$infoButtonBg: var(--blue-500);

/// Text color of an info button
/// @group button
$infoButtonTextColor: var(--surface-a);

/// Border of an info button
/// @group button
$infoButtonBorder: 1px solid var(--blue-500);

/// Background of an info button in hover state
/// @group button
$infoButtonHoverBg: var(--blue-300);

/// Text color of an info button in hover state
/// @group button
$infoButtonTextHoverColor: var(--blue-50);

/// Border color of an info button in hover state
/// @group button
$infoButtonHoverBorderColor: var(--blue-300);

/// Background of an info button in pressed state
/// @group button
$infoButtonActiveBg: var(--blue-600);

/// Text color of an info button in pressed state
/// @group button
$infoButtonTextActiveColor: var(--blue-100);

/// Border color of an info button in pressed state
/// @group button
$infoButtonActiveBorderColor: var(--blue-600);

/// Box shadow of an info button in focused state
/// @group button
$infoButtonFocusShadow: none;

/// Background of a success button
/// @group button
$successButtonBg: var(--green-500);

/// Text color of a success button
/// @group button
$successButtonTextColor: var(--surface-a);

/// Border of a success button
/// @group button
$successButtonBorder: 1px solid var(--green-500);

/// Background of a success button in hover state
/// @group button
$successButtonHoverBg: var(--green-300);

/// Text color of a success button in hover state
/// @group button
$successButtonTextHoverColor: var(--green-50);

/// Border color of a success button in hover state
/// @group button
$successButtonHoverBorderColor: var(--green-300);

/// Background of a success button in pressed state
/// @group button
$successButtonActiveBg: var(--green-600);

/// Text Color of a success button in pressed state
/// @group button
$successButtonTextActiveColor: var(--green-100);

/// Border color of a success button in pressed state
/// @group button
$successButtonActiveBorderColor: var(--green-600);

/// Box shadow of a success button in focused state
/// @group button
$successButtonFocusShadow: none;

/// Background of a warning button
/// @group button
$warningButtonBg: var(--orange-700);

/// Text color of a warning button
/// @group button
$warningButtonTextColor: var(--surface-a);

/// Border of a warning button
/// @group button
$warningButtonBorder: 1px solid  var(--orange-700);

/// Background of a warning button in hover state
/// @group button
$warningButtonHoverBg: var(--orange-300);

/// Text color of a warning button in hover state
/// @group button
$warningButtonTextHoverColor: var(--orange-50);

/// Border color of a warning button in hover state
/// @group button
$warningButtonHoverBorderColor: #FFB300;

/// Background of a warning button in pressed state
/// @group button
$warningButtonActiveBg: #FFA000;

/// Text color of a warning button in pressed state
/// @group button
$warningButtonTextActiveColor: $textColor;

/// Border color of a warning button in pressed state
/// @group button
$warningButtonActiveBorderColor: #FFA000;

/// Box shadow of a warning button in focused state
/// @group button
$warningButtonFocusShadow: none;

/// Background of a help button
/// @group button
$helpButtonBg:#9C27B0;

/// Text color of a help button
/// @group button
$helpButtonTextColor:var(--surface-a);

/// Border of a help button
/// @group button
$helpButtonBorder:1px solid #9C27B0;

/// Background of a help help in hover state
/// @group button
$helpButtonHoverBg:#8E24AA;

/// Text color of a help button in hover state
/// @group button
$helpButtonTextHoverColor:#ffffff;

/// Border color of a help button in hover state
/// @group button
$helpButtonHoverBorderColor:#8E24AA;

/// Background of a help button in pressed state
/// @group button
$helpButtonActiveBg:#7B1FA2;

/// Text color of a help button in pressed state
/// @group button
$helpButtonTextActiveColor:#ffffff;

/// Border color of a help button in pressed state
/// @group button
$helpButtonActiveBorderColor:#7B1FA2;

/// Box shadow of a help button in focused state
/// @group button
$helpButtonFocusShadow: none;

/// Background of a danger button
/// @group button
$dangerButtonBg: var(--red-500);

/// Text color of a danger button
/// @group button
$dangerButtonTextColor: var(--surface-a);

/// Border of a danger button
/// @group button
$dangerButtonBorder: 1px solid var(--red-500);

/// Background of a danger button in hover state
/// @group button
$dangerButtonHoverBg: var(--red-300);

/// Text color of a danger button in hover state
/// @group button
$dangerButtonTextHoverColor: var(--red-50);

/// Border color of a danger button in hover state
/// @group button
$dangerButtonHoverBorderColor: var(--red-300);

/// Background of a danger button in pressed state
/// @group button
$dangerButtonActiveBg: var(--red-600);

/// Text color of a danger button in pressed state
/// @group button
$dangerButtonTextActiveColor: var(--red-100);

/// Border color of a danger button in pressed state
/// @group button
$dangerButtonActiveBorderColor: var(--red-600);

/// Box shadow of a danger button in focused state
/// @group button
$dangerButtonFocusShadow: none;

/// Text color of a link button
/// @group button
$linkButtonColor:$primaryDarkerColor;

/// Text color of a link button in hover state
/// @group button
$linkButtonHoverColor:$primaryDarkerColor;

/// Text decoration of a link button in hover state
/// @group button
$linkButtonTextHoverDecoration:underline;

/// Box shadow of a link button in focused state
/// @group button
$linkButtonFocusShadow: none;

/// Background of a toggle button
/// @group button
$toggleButtonBg: #ffffff;

/// Border of a toggle button
/// @group button
$toggleButtonBorder: 1px solid #ced4da;

/// Text color of a toggle button
/// @group button
$toggleButtonTextColor: $textColor;

/// Icon color of a toggle button
/// @group button
$toggleButtonIconColor: $textSecondaryColor;

/// Background of a toggle button in hover state
/// @group button
$toggleButtonHoverBg: #e9ecef;

/// Border color of a toggle button in hover state
/// @group button
$toggleButtonHoverBorderColor: #ced4da;

/// Text color of a toggle button in hover state
/// @group button
$toggleButtonTextHoverColor: $textColor;

/// Icon color of a toggle button in hover state
/// @group button
$toggleButtonIconHoverColor: $textSecondaryColor;

/// Background of a toggle button in selected state
/// @group button
$toggleButtonActiveBg: $primaryColor;

/// Border color of a toggle button in selected state
/// @group button
$toggleButtonActiveBorderColor: $primaryColor;

/// Text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveColor: $primaryTextColor;

/// Icon color of a toggle button in selected state
/// @group button
$toggleButtonIconActiveColor: $primaryTextColor;

/// Hover background of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBg: $primaryDarkColor;

/// Hover border color of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBorderColor: $primaryDarkColor;

/// Hover text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveHoverColor: $primaryTextColor;

/// Hover icon of a toggle button in selected state
/// @group button
$toggleButtonIconActiveHoverColor: $primaryTextColor;

/// Width of a floating action button on speed dial
/// @group button
$speedDialButtonWidth: 4rem;

/// Height of a floating action button on speed dial
/// @group button
$speedDialButtonHeight: 4rem;

/// Font Size of a floating action button on speed dial
/// @group button
$speedDialButtonIconFontSize: 1.3rem;

/// Width of any action button on speed dial
/// @group button
$speedDialActionWidth: 3rem;

/// Height of any action button on speed dial
/// @group button
$speedDialActionHeight: 3rem;

/// Background color of any action button on speed dial 
/// @group button
$speedDialActionBg: #495057;

/// Background color of any action button on speed dial in hover state
/// @group button
$speedDialActionHoverBg: #343a40;

/// Color of any action button on speed dial 
/// @group button
$speedDialActionTextColor: #fff;

/// Color of any action button on speed dial in hover state
/// @group button
$speedDialActionTextHoverColor: #fff;