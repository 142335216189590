.scenario-search-box,
.comparison-search-box,
.lifecycle-search-box{
  width: 100%;
  margin-top: $spacing-sm;

  &-dropdown {
    width: 100%;
  }
}
