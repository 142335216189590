.details-panel {
  & > div{
    border: none;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2) !important;
  }
  &__close-button {
    @include z-index(details-panel-close);
    position: absolute;
    top: $ANT-spacing-18;
    right: $spacing-m;
    cursor: pointer;
    color: $color-grey-dark;
    border: none;
    background: none;
    box-shadow: none;
  }

  .details-panel-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 0;
    overflow:hidden;
  }

  .details-panel-title {
    @include z-index(details-panel-title);
    width: 100%;
    height: $details-panel-title-height;
    background-color: $color-white;
    border-bottom: 1px solid $color-grey-lighter-1;
  }

  .details-panel-body {
    width: 100%;
    height: calc(100% - #{$details-panel-title-height} - #{$spacing-s});
    overflow-y: hidden;

    &:hover {
      overflow-y: overlay;
    }
  }

  .details-card {
    float: left;
    width: 100%;
    padding: $ANT-spacing-18 $ANT-spacing-24;
    border-top: 1px solid $color-grey-lighter-1;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
    }

    &__title {
      padding: 10px 0;
      font-weight: 600;
      font-size: 14px;
      flex-grow: 2;
      color: $color-grey-dark-1;
    }

    &__action {
      width: fit-content;
      padding: 0;

      button,
      .button {
        color: $color-grey-dark-1;
        min-height: $ANT-spacing-24;
        min-width: $ANT-spacing-24;
        max-height: $ANT-spacing-24;
        max-width: $ANT-spacing-24;
        padding: 1px 0;
      }
      button:hover {
        color: $color-green;
      }
    }

    &__content {
      width: 100%;
      padding: 10px 0;
    }

  }

  .detail-panel-name {
    font-family: $font-family-tertiary;
    font-weight: $semibold;
    color: $color-text-title;
    padding: 0;
    width: calc(100% - #{$spacing-xl});
    span.ant-typography {
      font-size: $font-size-ms;
      padding: $ANT-spacing-22 $ANT-spacing-24;
    }
  }
  .ant-divider{
      margin: $spacing-m 0;
      &.toolbar-divider {
          margin-top: 0px;
          margin-bottom: $spacing-m;
      }
  }
  .ant-input-group-addon{
    @extend .crop-text;
    text-align: right;
    width: 25%;
    @include breakpoint('desktop-md') {
        width: 33%;
    }
  }
  .autocomplete {
      margin: 0;
      width: 75%;
      display: inline-block;

      @include breakpoint('desktop-md') {
          width: 67%;
      }
  }
  hr{
    border-top: 1px solid $color-divider;
  }
  .ant-collapse-header {
    color: $color-navy-dark;
    font-size: $font-size-ssm;
    .icon{
      padding-right: $spacing-sm;
    }
  }

  .h3 {
    margin-bottom: $spacing-sm;
  }
  .panel-section{
    background-color: $color-white;
  }

  &.no-collapse{
    padding: $spacing-sm;
    margin-top: $spacing-sm;
  }
  .insert-value-text{
    color: $color-steel-light;
  }
  .input-text-style{
    color: rgba(0, 0, 0, 0.65);
    font-size: $font-size-s;
  }
  .field-container{
    display: block;
    padding: 0;
    &.editable-field{
      padding: $spacing-xs 0;
    }
    label{
      @extend .small-label;
      float: left;
    }

    .form-product-type {
      font-size: $font-size-s;
    }

    .form-action {
      width: 100%;
      padding-top: $spacing-xl;
    }

    .product-name-button {
      margin-left: 10px;
      padding: 6px 0;
      width: 32px;
      height: 32px;
      vertical-align: -6px;
    }

    .form-comp-desc {
      font-size: 14px;
      color: $color-grey-light;
    }
  }
  .field-text{
    padding: 0;
    &.disabled{
      cursor: not-allowed;
    }
    .button.edit-field{
      visibility: hidden;
    }
    &:hover{
      .button.edit-field{
        visibility: visible;

        .anticon {
          margin-right: 4px;
        }
      }
    }
  }
  .ant-form-item{
    padding: 0;
    margin: $ANT-spacing-8 0 0 0;
  }

  .action-toolbar{
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
  }

  .product-title{
    + .action-toolbar{
      position: relative;
      margin-top: -$spacing-m;
      margin-bottom: $spacing-sm;
      bottom:unset;
    }
  }

  .icon-button,
  .icon-button button{
    width: $icon-button-size;
    height: $icon-button-size;
    margin: 0 $spacing-s;
    border: none;
    background: none;
  }
  .select-template{
    width: 100%;
  }
  .field-group-container{
    button{
      margin-top: $spacing-sm;
    }
  }
}
.add-space-top{
  margin-top: $spacing-sm;
}
