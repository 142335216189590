.user-profile{
  padding: $spacing-m;
  .user-profile-content{
    border-radius: $border-radius;
    margin-bottom: $font-size-m;
  }

  .two-factor-authentication-question {
    cursor: pointer;
  }
}

.two-factor-authentication-link {
  color: white !important;
}
