.product-detail-general-info {
  .general-info_input-item {
    padding: $spacing-s 0;
    width: 100%;
  }
  .product-general-info-buttons {
    margin-top: $spacing-sm;
    button {
      margin-right: $spacing-s;
    }
  }
  .antd-custom-label_name, .antd-custom-label_type {
    width: 25%;
  }
}