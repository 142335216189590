

.frame {
  overflow: hidden;
	display: flex;
	min-height: 100%;
  flex-direction: column;

  .main-content {
    display: flex;
    flex-grow: 1;
  }
}

.middle-container{
  background-color: $color-background;
}

.page-container {
  padding: $grid-padding;
  &.v1 {
    padding: 0 2.5rem;
  }
}

.user-menu a > i {
	margin-right: $spacing-s;
}

.full-sized-modal {
  min-width: 100% !important;
  padding-bottom: 0px;
  textarea {
    margin-bottom: 0;
  }
  .ant-modal-content {
    min-height: 100vh;
    border-radius: 0;
  }
  .grey-modal{
    .ant-modal-content,
    .ant-modal-header,
    .impactSelectorDialog .filters {
      background-color: $color-background;
      .title {
        margin-bottom: 0;
        text-align: center;
      }
    }
  }
  .ant-collapse-borderless > .ant-collapse-item{
    border: none;
  }
  .ant-modal-body{
    padding-left: 0;
    padding-top: 0;
  }
  .ant-tree .ant-tree-node-content-wrapper,
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    &, &:hover, &:active{
      background: none;
    }
  }
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected .category-name {
    color: $color-green;
  }
}

.middle-panel {
	overflow-y: auto;
  height: 100vh;

  &--withBanner {
    height: calc(100vh - 70px);
  }
}

.product-import-search-modal {
  top: 2vh;
}
