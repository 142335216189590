.customer-details-form{
  h4{
    padding-bottom: 1em;
    color: $color-text-grey-dark;
  }
  .field-container{
    padding-bottom: $ANT-spacing-8;
    label{
      @extend .small-label;
      padding: 0.4em;
    }
  }
}
