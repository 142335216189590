.timeline-item-wrapper {
  height: 100%;
  background-color: $color-background;
  flex-flow: row nowrap;
  .timeline-item {
    padding-bottom: $spacing-3xl;
    height: 100%;
  }

  .timeline-item-title {
    height: 100%;
    width: 100%;
    flex-flow: row nowrap;
  }

  .timeline-item_name {
    text-align: right;
    width: 100px;
    p {
      margin: 0;
      padding: $spacing-m 0;
    }
  }

  .timeline-item-line {
    text-align: center;
    width: fit-content;
    padding: 0 $spacing-sm;
    .timeline-item-head {
      font-size: 50px;
      color: $color-steel-light;
    }
    .timeline-item-head.add-phase-icon {
      color: $color-green;
      font-size: $font-size-ml;
    }
    .timeline-item-tail {
      position: absolute;
      top: 50px;
      left: 49%;
      height: calc(100% - 50px);
      border-left: $spacing-xs solid $color-steel-light;
    }
  }

  .timeline-item_content {
    padding-top: 7px;
    flex-grow: 2;
  }

  .timeline-item_content_input {
    padding: 0 24px;
  }

  .phase-name-input {
    height: 50px;
  }
  .inventory-tree-header-labels {
    margin: -$spacing-l 0 0;
    width: 100%;
    position: absolute;
    div:nth-child(1){
      padding-left: $spacing-s;
    }
  }
  .inventory-tree{
    transition: height 2.15s ease-out;
    padding-left: $spacing-sm;
  }

  .timeline-item-wrapper_add-phase {
    .timeline-item_name .timeline-item_name-content {
      padding: $spacing-xs 0;
      margin-top: 0;
    }
    .timeline-item-tail {
      display: none;
    }
  }
}

.drag-enabled{
  .timeline-item-wrapper{
    border: none;
    background-color: $color-steel-bright;
    margin-top: $spacing-sm;
    .timeline-item{
      padding: $spacing-m 0;
    }
    .timeline-item-line {
      .timeline-item-tail {
        border-left: none;
      }
    }
    &.isDragging {
      border: 1px solid $color-green;
    }
  }
  .drag-action-icon{
    font-size: $font-size-ml ;
    color: $color-steel-dark;
    position: relative;
    top: $spacing-sm;
    right: $spacing-sm;
  }
  .timeline-container:last-child {
    .timeline-item {
      padding: $spacing-sm 0;
    }
  }

}

.ant-timeline-item .ant-timeline-item-content {
  top: 0;
}

.ant-timeline-item-last {
  .add-leg-button {
    color: $color-green;
  }
  .ant-timeline-item-head {
    background-color: transparent
  }
}

.timeline-item-wrapper_add-phase {
  .timeline-item_name .timeline-item_name-content {
    padding: $spacing-xs 0;
    margin-top: 0;
  }
}
.lifecycle-toolbar{
  margin-bottom: $spacing-m;
}

.timeline-container:last-child{
  .timeline-item-tail {
    display: none;
  }
}


.timeline-item_name-content {
  padding: $spacing-xs $spacing-sm;
  margin-top: 12px;
  display: inline-block;
  overflow-wrap: break-word;
  width: 100%;
}


.phase-name-with-first-letter {
  cursor: pointer;
  width: 170px;

  &--sm {
    width: 70px;
  }
  &:hover {
    color: $color-text-primary;
    .timeline-item-tail {
      border-color: $color-positive-impact;
    }
    .timeline-item_first-letter {
      border-color: $color-positive-impact;
    }
  }
}

.timeline-item_first-letter {
  display: inline-block;
  border: $spacing-xs solid $color-steel-light;
  width: $spacing-5xl;
  height: $spacing-5xl;
  border-radius: 50%;
  text-transform: uppercase;
  font-size: $font-size-m;
  text-align: center;
  line-height: 2.3;
}

.phase-name-with-first-letter.on {
  .timeline-item_first-letter {
    border-color: $color-positive-impact;
    color: $color-text-primary;
  }
  .timeline-item-tail {
    border-color: $color-positive-impact;
  }
  .timeline-item_name-content {
    color: $color-text-primary;
  }
}

.timeline-container.lifecycle-scenario{
  width: 100%;
}

.lifecycle {
  margin-top: $spacing-m;
  &--drag-enabled{
    width: calc(100% - #{$details-panel-width});
  }
}
