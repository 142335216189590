.switches-container {
  text-align: right;
  .switch {
    display: inline-block;
    border-right: 1px solid $color-divider;
    padding: 0 $spacing-m;
    &:last-child {
      border-right: none;
      padding-right: 0px;
    }
  }
}

.switch {
  text-align: right;
  margin-bottom: $spacing-m;
  > button {
    width: $spacing-4xl;
  }
  .ant-switch-inner {
    margin: 0;
    font-family: monospace;
  }
}

.inventory {
  .inventory-tabs {
    @include breakpoint('laptop') {
      .ant-tabs-nav .ant-tabs-tab {
        margin-right: $spacing-l;
      }
    }
  }
  .inventory-actionbar_item_select {
    width: 80%;
  }
  .inventory-actionbar_item {
    text-align: right;
    label {
      width: auto;
    }
  }
}

.inventory-impact-result {
  background-color: $color-steel-lighter;
  border-radius: $border-radius;
  padding: $spacing-m;
  font-size: $font-size-m;
  text-align: center;
  color: $color-steel-dark;
  margin-top: $spacing-3xl;
  width: 100%;
  @include breakpoint('laptop') {
    font-size: $font-size-sm;
  }
  .amount {
    font-weight: bold;
  }
}

.inventory-carbon-translator {
  margin-top: $spacing-2xl;
  .button {
    color: $color-text-grey-dark;
  }
  .new-badge {
    margin-left: $spacing-sm;
    text-transform: uppercase;
  }
  p {
    margin-top: $spacing-sm;
    color: $color-text-grey;
  }
}

// ######################### TABS CSS ##############################

// fix for tabs nav bar error: https://github.com/ant-design/ant-design/issues/13423
.ant-tabs-nav-container-scrolling {
  padding: 0px;
}
.ant-tabs-tab-arrow-show {
  display: none;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 0px;
}

.ant-table-footer {
  padding: 0px;
  & > div {
    padding: $spacing-sm;
  }
}

.chartTitle {
  padding-left: $spacing-xs;
  padding-right: $spacing-xs;
  font-family: $font-family-primary;
  text-align: center;
  color: $color-navy;
  font-size: $font-size-l;
}

.impact-pending {
  padding-right: $spacing-xs;
  opacity: 0.5;
}
