// ######################### TABLE ##############################
$table-border: 1px solid $color-table-border;
.leaf-inventory-container {
  .switch {
    text-align: right;
  }
  .leaf-inventory-table{
    padding-bottom: $ANT-spacing-16;
    table{
      border-top: $table-border;
      border-bottom: $table-border;
      width: 100%;
      thead{
        border-right: $table-border;
        border-left: $table-border;
        tr{
          th{
            background: $color-table-header;
            &:hover{
              background: $color-table-header;
            }
            &.compare-product-divider{
              border-left: $table-border;
            }
          }
        }
      }
      tbody{
        tr{
          background-color: white;
          border: $table-border;
          td{
            &.compare-product-divider{
              border-left: $table-border;
            }
          }
        }
      }
      .ant-table-selection-col, .ant-table-selection-column {
        display: none;
      }
      .positiveBarInTable {
        height: $spacing-l;
        background: $gradient-positive-to-right;
        &--reversed {
          background: $gradient-positive-to-left;
        }
      }
      .negativeBarInTable {
        height: $spacing-l;
        background: $gradient-negative-to-right !important;
        &--reversed {
          background: $gradient-negative-to-left !important;
        }
      }
    }
    .footer{
      border: $table-border;
      border-top: none;
      background: white;
      font-weight: bold;
      div:first-child{
        font-weight: normal;
      }
    }
    &.percentages{
      thead{
        th.impact-bar,
        th.compare-impact-bar{
          text-align: center !important;
        }
      }
      td.impact-bar,
      td.compare-impact-bar{
        padding-top: 0px !important;
        padding-bottom: 0px !important;
      }
      .bar-item {
        display: inline-block;
        text-align: left;
        margin-bottom: -$spacing-s;
        &.positiveBarInTable{
          background: transparent;
        }
      }
      .impact-bar{
        .negativeBarInTable{
          background: $gradient-negative-to-left;
        }
        .positiveBarInTable{
            background: $gradient-positive-to-left;
        }
      }
      .compare-impact-bar{
        .bar-item{
          text-align: right;
          &.outside{
            .label{
              left: 10%;
            }
          }
        }
        .negativeBarInTable {
          background: $gradient-negative-to-right;
        }
        .positiveBarInTable{
          background: $gradient-positive-to-right;
        }
      }
    }
  }
  .summary{
    color: $color-text;

    td{
    font-weight: bold;
    color: $color-text;
    }
  }
}

