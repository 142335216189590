@mixin item-connection {
    .connected .connection:hover,
    .connect-exists .connection {
        .icon.input,
        .icon.output {
            color: $color-text-inverse;
            opacity: 0;
        }
    }
    .connect-new:not(.connect-start) .connection .icon.add {
        opacity: .5;
    }
    .connect-new:not(.connect-start) .connection:hover .icon.add {
        opacity: 1;
    }
    .connect-exists:not(.connecting) .connection,
    .connected .connection  {
        .icon.input,
        .icon.output {
            opacity: 0;
        }
        .icon.remove {
            opacity: 1;
        }
    }
    .connect-exists.connecting .connection,
    .connected .connection {
        .icon.remove {
            opacity: 0;
        }
    }
    .connect-new .connection:hover {
        .icon.input,
        .icon.output {
            opacity: 0;
        }
    }
    .connect-exist .connection:hover {
        .icon.input,
        .icon.output {
            opacity: 0;
        }
    }
}


.model .workspace > .process .process-col {
    &.input-list {
        @include item-connection;
        .connected .connection:hover,
        .connect-exists .connection, .connect-start .connection{
            .icon.input {
                opacity: 1;
            }
        }
        .connection {
            right: $spacing-2xl;
        }
        .item:not(.connect-new):not(.connect-exists) .connection:hover > .icon.input {
            opacity: 1;
        }
    }
    &.output-list {
        @include item-connection;
        .connected .connection:hover,
        .connect-exists .connection, .connect-start .connection {
            .icon.output {
                opacity: 1;
            }
        }
        .connection {
            left: $spacing-2xl;
        }
        .item:not(.connect-new):not(.connect-exists) .connection:hover > .icon.output {
            opacity: 1;
        }
    }
    &.process-list {
        .connection {
            height: $spacing-ml;
            width: $spacing-ml;
            .icon {
                font-size: $font-size-s;
            }
        }
        .connect-exists .connection {
            background-color: $color-steel;
        }

        .connection,
        .connection:hover {
            > .icon.output {
                opacity: 0;
            }
        }
        .input-list {
            @include item-connection;
            .connected .connection:hover,
            .connect-exists .connection, .connect-start .connection {
                .icon.output {
                    opacity: 1;
                }
            }
            .connection {
                left: $spacing-2xl;
                &:hover .icon.output {
                    opacity: 1;
                }
            }
        }
        .output-list {
            @include item-connection;
            .connected .connection:hover,
            .connect-exists .connection, .connect-start .connection {
                .icon.input {
                    opacity: 1;
                }
            }
            .connection {
                right: $spacing-2xl;
                &:hover .icon.input {
                    opacity: 1;
                }
            }
            .item:not(.connect-new):not(.connect-exists) .connection:hover > .icon.input {
                opacity: 1;
            }
        }
    }

    .connection {
        background-color: $color-background-light;
        border: 1px solid $color-steel;
        border-radius: 50%;
        height: $spacing-xl;
        position: absolute;
        width: $spacing-xl;
        > .icon {
            color: $color-steel;
            font-size: $font-size-m;
            left: 50%;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: color $default-animation-speed, opacity $default-animation-speed;
        }
    }

    .item { // to increase specificity.
        &.connected .connection {
            border-color: $color-navy;
            background-color: $color-steel;
        }
        &.connect-new, &.connect-exists, &.connect-start {
            background-color: $color-steel-lighter;
            border-color: $color-steel;
        }
        &.connect-exists .connection, &.connect-start .connection  {
            background-color: $color-steel;
            border: 1px solid $color-navy;
            .icon {
                color: $color-text-inverse;
            }
        }
    }

    .edit .connection {
        border: 1px dashed $color-steel;
    }
}
