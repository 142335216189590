form {
	.action {
		text-align: center;
	}
}

.form-error {
	color: $color-status-error;
	margin-bottom: $spacing-ml;
}

.antd-custom-label {
	padding: 8px 11px;
	color: rgba(0, 0, 0, 0.65);
	font-weight: normal;
	font-size: 14px;
	line-height: 1;
	text-align: center;
	background-color: #fafafa;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	transition: all 0.3s;
}

.select-with-prefix {
  margin: $spacing-sm 0;
  &.small-margins {
      margin: $spacing-s 0;
  }
  &.selected{
    label,
    .prefix{
      color: white;
      background-color: $color-green;
      border-color: $color-green;
    }
  }
  label,
  .prefix{
      @extend .antd-custom-label;
      @extend .crop-text;
      text-align: right;
      width: 25%;
      border-right: none;
      @include breakpoint('desktop-md') {
          width: 33%;
      }
      .anticon{
        margin-top: -$spacing-xs;
      }
  }
  & > .ant-select {
      margin: 0;
      width: 75%;
      display: inline-block;
      @include breakpoint('desktop-md') {
          width: 67%;
      }
  }
  &.with-suffix{
    & > .ant-select {
      //padding-right: $spacing-xl;
    }
  }
  .suffix{
    display: none;
    position: absolute;
    z-index: 2;
    margin-left: -$spacing-xl;
    &.on{
      display: inline-block;
    }
  }
}
.ant-select-dropdown{
  text-align: left;
  @include z-index(select-dropdown);
  .ant-divider{
    margin: $spacing-s 0 0;
  }
}

.transport-distance-input {
  .ant-input-suffix {
    z-index: 1;
  }
}

.transparent{
  input,
  .ant-select-selection{
    border: none;
    background: none;
    &:not(:focus)::placeholder,
    .ant-input-affix-wrapper .ant-input-prefix,
    .ant-select-selection__placeholder{
      color: $color-text;
    }
  }
  &.ant-input-affix-wrapper .ant-input-prefix,
  .ant-select-arrow{
    color: $color-text;
  }
}

.button-right-margin{
  display: inline-block;
  margin-right: $ANT-spacing-8;
}

.ant-form-item {
  padding-bottom: $form-padding;
  margin-bottom: 0;
}

.small-label{
  display: block;
  font-size: $font-size-xxs;
  color: $color-text-grey;
  height: auto;
}

.select-transparent {
  &:not(.ant-select-customize-input) .ant-select-selector {
    color: $color-grey-dark;
  }
}
