
/* Inherited overrides */

html{
  --antd-wave-shadow-color: $color-green;
}

button.button > i.anticon {
    margin-right: $spacing-s;
}

button.icon > .anticon {
    margin-right: 0px;
}

.ant-btn-primary,
.ant-btn-primary.primary[disabled]:hover {
    @extend .button, .primary;
    border: none;

}

.ant-btn-danger,
.ant-btn-danger.danger[disabled]:hover {
    @extend .button, .danger;
    border: none;

}

.workspace .input-list .item.input .ant-select-auto-complete.ant-select .ant-select-selection__placeholder,
.workspace .process-list .item.process .output-list .item.output .ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
    left: auto;
    right: 0px;
}
.workspace .process-list .item.process .input-list .item.input .ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
    left: 0px;
    right: auto;
}
.workspace-list .ant-list-item-content,
.lifecycle-list .ant-list-item-content
.process-list .ant-list-item-content,
.product-list .ant-list-item-content {
    flex-grow: initial;
}


/* Pure overrides */

.ant-btn,
.ant-checkbox,
.ant-checkbox-wrapper,
.ant-dropdown,
.ant-form-item,
.ant-input,
.ant-input-affix-wrapper,
.ant-modal,
.ant-select,
.ant-table,
.ant-tabs,
.ant-tooltip {
    font-family: $font-family-default;
}

.ant-btn:not([disabled]):active {
    transition: background-color $default-animation-speed, box-shadow $default-animation-speed, opacity $default-animation-speed, transform $default-animation-speed;
}

.ant-btn:hover,
.ant-btn:focus {
    color: $color-green;
    border-color: $color-green;
}

.ant-btn-primary:active,
.ant-btn-primary:focus,
.ant-btn-primary.active {
    background-color: $color-green;
    border-color: $color-green;
    color: $color-text-inverse;
}

.ant-btn-primary.tertiary:active,
.ant-btn-primary.tertiary:focus,
.ant-btn-primary.tertiary.active {
    background-color: $color-orange;
    border-color: $color-orange;
    color: $color-text-inverse;
}

.ant-notification-notice-close {
    text-decoration: none;
}

.ant-dropdown {
    font-family: $font-family-default;
}

.ant-dropdown-menu-item > a {
    text-decoration: none;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
    background-color: $color-background-dark;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
    background-color: $color-background-dark;
    color: $color-navy;
}

.ant-checkbox-checked:after {
    border-color: $color-green;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: $color-green;
    border-color: $color-green;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $color-green;
}

.ant-input:hover {
    border-color: $color-green;
}

.ant-input:focus {
    border-color: $color-green;
    box-shadow: none;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: $color-green;
}

.ant-pagination-item-active {
    border-color: $color-green;
}
.ant-pagination-item-active a {
    color: $color-green;
}

.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
    border-color: $color-green;
    box-shadow: none;
}
.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
    margin: -10px 0px 0px 0px;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown {
    color: $color-green;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select .ant-selector:hover,
.ant-select-selection:focus,
.ant-select-selection:active,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:active,
.ant-input-affix-wrapper-focused,
.ant-input-number-focused,
.ant-input-number:hover,
.ant-input-number:active {
    border-color: $color-green;
    box-shadow: none;
}
.ant-select-focused.ant-select-single

.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: transparent;
  .anticon-check{
    color: $color-green;
  }
}

.ant-select-item-option-selected{
  &:hover{
    background-color: $color-grey-bright;
  }
}

.ant-select-selection:hover {
    border-color: $color-green;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected{
  background-color: transparent;
}

.ant-select-dropdown-menu-item:hover,
.ant-select-dropdown-menu-item-active {
    background-color: $color-background-dark;
    color: $color-navy;
}

.ant-switch-checked {
    background-color: $color-green;
}

.ant-switch-inner{
  margin: 0 6px 0 24px; // TODO this fix a bug in ant library. Check if it will be fixed in next ant versions
  font-family: monospace;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: $color-table-header;
}

.ant-table-tbody > tr:not(.ant-table-row-selected) > td.ant-table-column-sort{
    background: transparent;
}
.ant-table-thead > tr > th.ant-table-column-sort  {
    background-color: $color-table-header;
}

.ant-table-tbody > tr:not(.ant-table-row-selected) > td.ant-table-column-sort{
    background: transparent;
}

.ant-tabs-ink-bar {
    background-color: $color-green;
}
.ant-tabs-tab
{
  .ant-tabs-tab-btn
  &:active,
  &:link {
    color: $color-steel-darker;
  }
  &.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:hover{
    color: $color-green;
    text-shadow:none;
    font-weight: normal;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector
{
  border-color: $color-green;
  box-shadow: 0 0 0 2px rgba(86, 202, 25, 0.2);
}

.ant-tabs .ant-tabs-large-bar .ant-tabs-nav-container{
  font-size: $font-size-s;
}

.ant-tabs-nav .ant-tabs-tab-active{
  font-weight: normal;
}
.ant-tabs-nav .ant-tabs-tab{
  margin: 0 48px 0 0;
}

.ant-tree li .ant-tree-node-content-wrapper:hover,
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected:hover {
    background-color: $color-steel-light;
    color: $color-navy;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
    background-color: $color-background;
}

.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
    text-decoration: none;
}

.has-error .ant-input-affix-wrapper:hover .ant-input,
.has-error .ant-input:hover {
    border-color: $color-status-error;
}
.has-error .ant-input:focus {
    box-shadow: none;
}

.no-margin {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}

span.ant-typography-ellipsis{
  max-width: 100%;
  & button{
    span{
      @extend .crop-text;
      width: 100%;
      padding-right: $spacing-m;
      vertical-align: -$spacing-xs;
    }
  }
}

.section-user-account div.ant-menu-submenu-title,
.section-user-account .ant-menu-item,
.section-workspace div.ant-menu-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
}

.ant-menu{
  .ant-menu-item,
  .ant-menu-submenu{
    &:hover,
    &:active,
    &.ant-menu-submenu-active
    &.ant-menu-submenu-open{
      color: $color-text-primary;
      background: none;
      a, .ant-menu-submenu-title{
        color: $color-text-primary;
      }
      .ant-menu-submenu-arrow{
        &::before,
        &::after{
          background: $color-text-primary;
        }
      }
    }
  }
}

.ant-collapse-borderless {
  background-color: $color-background-light;
}

.ant-progress-status-success .ant-progress-bg{
  background-color: $color-orange;
}
.ant-progress-bg{
  background-color: $color-green;
}

.ant-alert.icon-alert{
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  .ant-alert-icon{
    position: relative;
    top: 0;
    left: 0;
  }
}

.inventory-tree .inventory-item {
  .ant-tree-switcher,
  .ant-tree-node-content-wrapper {
    height: $tree-item-height;
    align-items: center;
  }
  .ant-tree-switcher{
    border: 1px solid transparent;
    border-right: none;
  }
  .ant-tree-node-content-wrapper {
    border: 1px solid transparent;
    border-left: none;
  }
  &.ant-tree-treenode-selected
  {
    .ant-tree-node-content-wrapper,
    .ant-tree-switcher {
      border: 1px solid $color-green;
    }
    .ant-tree-node-content-wrapper {
      border-left: none;
    }
    .ant-tree-switcher{
      border-right: none;
    }
    .actions {
      opacity: 1;
      visibility: visible;
    }
  }
  &:hover {
    .ant-tree-node-content-wrapper,
    .ant-tree-switcher {
      background-color: $color-steel-lighter;
    }
    .actions {
      visibility: visible;
    }
  }
  .ant-tree-node-content-wrapper {
    display: flex;
    padding-right: $spacing-m;
  }
}

div.input-wrapper, div.select-wrapper {
  .ant-form-item-control-input {
    margin-top: -10px;
  }
  .ant-form-item-label {
    @include z-index(input-label);
    background-color: $color-white;
    padding: 0 4px 0 0;
    width: fit-content;
    background: linear-gradient(180deg, transparent 50%, #fff 50%);
  }
}

.ant-tree-treenode-motion {
  width: 100%;
}

.sunburst-viz {
  text-align: center;
}

.sunburst-viz .text-contour {
  stroke: none !important;
}

.sunburst-tooltip {
  max-width: none !important;
}

.ant-notification {
  @include z-index(global-loader);
}
