.conversion {
    margin-bottom: $spacing-sm;
    &:hover {
        .ant-card-extra, .conversion-product-dropdown {
            visibility: visible;
        }
    }

    .ant-card-extra, .conversion-product-dropdown {
        visibility: hidden;
    }

    .conversion-side {
        margin: $spacing-sm;
        .add-conversion-button{
          span {
            display: inline;
            padding: 0;
          }
        }
        .rotated {
          line-height: 2.3;
      }
    }

    .ant-card-head {
        position: relative;
    }

    .conversion-wrapper {
        margin: $spacing-sm 0;
        display: flex;
        position: relative;

        .ant-input-group {
            display: flex;
        }
        .ant-input-group-addon {
            padding: 0 $spacing-s;
        }
        .ant-input-group-addon:first-of-type {
            width: $maximum-product-width;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: left;
            line-height: 2;
        }
        .ant-input {
            width: 30%;
            min-width: $spacing-4xl;
            padding: 0 $spacing-s;
        }
        .ant-input-group-addon:last-of-type {
            width: 20%;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 2;
            min-width: $spacing-5xl;
        }
    }

    .source {
        color: $color-navy;
    }

    .remove {
        color: $color-red;
    }

    .target {
        color: $color-green;
    }

    .rotated {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        position: relative;
        z-index: 1;
    }
    .more-actions-btn {
        padding: 0 $spacing-xs;
    }
}
