@mixin sidebar-message($color) {
  margin: 0;
  overflow: hidden;
  padding: 0 $spacing-4xl;
  .ant-alert-warning {
    padding: 0 0 $spacing-sm 0;
    .ant-alert-icon {
      color: $color;
    }
    background-color: transparent;
    border: none;
    .ant-alert-message {
      @extend .crop-text;
      font-size: $font-size-xs;
      color: $color;
    }
  }
  .button {
    font-size: $font-size-xs;
    transition: all $default-animation-speed;
    &:hover {
      color: $color;
      border-color: $color;
    }
  }
}

.environment-tag {
  width: 100%;
  overflow: hidden;
  color: $color-white;
  border: none;
  border-radius: 0;
  font-size: $font-size-sm;
  padding: $ANT-spacing-4;
  text-transform: capitalize;
  background-color: deepskyblue;
  text-align: center;
  &.development {
    background-color: limegreen;
  }
  &.beta {
    background-color: magenta;
  }
  &.alpha {
    background-color: goldenrod;
  }
}

// TODO fix for ANT bug. Remove when it will be fixed in the next package versions.
// The bug show sidebar icons of submenu (like workspace or account) not vertically aligned in collapsed mode.
.ant-menu-inline-collapsed .ant-menu-submenu-title {
  padding-left: 32px !important;
}

.sidebar-flex {
  display: flex;
  align-items: center;
}

.ant-layout-sider-has-trigger {
  padding-bottom: $sidebar-padding-bottom;
}

.submenu-popup {
  .submenu-item {
    line-height: 1.5;
    height: auto;
    min-width: $sidebar-submenu-width-sm;
    max-width: $sidebar-submenu-width;

    a,
    a:visited,
    a:active {
      color: $color-grey-dark;
      text-decoration: none;
    }
    &.active {
      color: $color-text-primary;
    }
    &.version {
      text-align: center;
      font-size: $font-size-xxxss;
      color: $color-grey;
      padding: $spacing-s 0 $spacing-ml;
    }
    &:first-child {
      padding-top: $spacing-sm;
    }
  }
  .ant-menu-item-divider {
    margin: $spacing-sm 0;
  }
  .ant-menu-item-group-list {
    max-height: 40vh;
    overflow-y: auto;
    position: relative;
  }
}

.model {
  .sidebar-content {
    background-color: $color-navy;
    color: $color-text-inverse;
    height: 100vh;
    overflow-y: auto;
    a,
    a:visited,
    a:link {
      color: $color-text-inverse;
      text-decoration: none;
    }
    .ant-divider {
      background-color: $color-steel-dark;
      min-width: 0;
      width: 60%;
      margin: $spacing-m auto;
    }
    &.collapsed {
      .hide-collapsed {
        display: none !important;
      }
      .section-logo {
        justify-content: center;
      }
      .section-footer {
        visibility: hidden;
      }
    }
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
    }
    .sidebar-section {
      .anticon {
        margin-right: 0 !important;
      }
      &.section-logo {
        border-bottom: 1px solid $color-grey;
      }
      flex-grow: 0;
      &.position-bottom {
        align-items: flex-end;
      }
      &.section-logo {
        padding: $grid-padding;
        background-color: $color-navy-dark;
        margin-bottom: $spacing-s;
        @extend .sidebar-flex;
        .sidebar-trigger {
          display: inline-block;
          font-size: $font-size-sm;
          height: $logo-height;
        }
        .logo-link {
          @extend .sidebar-flex;
          padding-left: $sidebar-padding;
          .logo {
            height: $logo-height;
          }
          .logo-text {
            color: $color-text-inverse;
            font-size: $font-size-mml;
            margin: 0;
            padding-left: $spacing-sm;
          }
        }
      }

      &.section-workspace {
        flex: 2;
      }
      &.section-messages {
        flex: 1;
      }

      &.section-footer {
        text-align: center;
        font-size: $font-size-xxs;
        opacity: 0.5;
        padding-top: $sidebar-padding;
        padding-bottom: calc(#{$chatbot-button-height} + #{$ANT-spacing-8});
      }
      .section-menu {
        flex-grow: 0;
        background: none;
        border: none;
        color: $color-text-inverse;
        align-self: flex-start;
        padding-bottom: $ANT-spacing-16;
        .ant-menu-submenu-vertical {
          color: $color-text-inverse;
        }
        .ant-menu-item.ant-menu-item-only-child .anticon {
          margin-right: $sidebar-icon-margin;
        }

        .menu-item,
        .menu-item .ant-menu-submenu-title {
          line-height: 2em;
          height: auto;
          background-color: transparent;
          &:hover {
            background-color: transparent;
          }
          &:visited,
          &:link {
            fill: $color-text-inverse;
          }
          .active,
          &.ant-menu-item-selected {
            color: $color-text-primary;
          }
          .custom-icon {
            fill: $color-text-inverse;
          }
          .ant-menu-submenu-arrow {
            padding-left: $ANT-spacing-8;
            &::before,
            &::after {
              opacity: 1;
              background: $color-text-inverse;
            }
          }

          &__icon {
            float: left;
            width: fit-content;
          }

          &__textContent {
            float: left;
            width: calc(100% - 20px);
            padding: 0 $ANT-spacing-8;
          }

          &__label {
            float: left;
            width: 100%;
            color: $color-white;

            &:hover {
              color: $color-green;
            }

            &--subscript {
              line-height: 1em;
              font-size: $spacing-sm;
              color: $color-grey !important;
            }
          }
        }
      }
    }
  }
}

.small-badge {
  display: inline-block;
  font-size: $font-size-xxs;
  line-height: 1.5em;
  margin-left: $spacing-s;
  background-color: magenta;
  color: $color-text-inverse;
  border: none;
}
