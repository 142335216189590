body, html {
    height: 100%;
    margin: 0px;
}

h1, h2, h3, h4 {
    margin: 0px;
}

ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

ul > li {
    margin: 0px;
    padding: 0px;
}

.reset-width {
    width: initial;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
