.product-tag {
  margin-bottom: $ANT-spacing-8;
}

.save-tag-button {
  margin-right: $ANT-spacing-8;
}

.tag-color_wrapper {
  position: relative;
  display: flex;
}

.tags-form_tags {
  margin: 0 0 $font-size-sm;
  .colored-tag {
    font-size: $font-size-xs;
    padding: $spacing-xs $spacing-xxs;
    .close-colored-tag-icon {
      font-size: $font-size-xxxss;
    }
  }
  &__empty-text{
    text-align: center;
    color: $color-text-grey;
    font-size: $font-size-ssm;
  }
}

.tags-action {
  margin-top: $spacing-m;
}
