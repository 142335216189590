.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus,
.p-dropdown:not(.p-disabled).p-focus,
.p-inputtext:enabled:focus,
.p-button:focus {
    box-shadow: 0 0 0.15rem 0 $primaryColor;
}

.p-dropdown,
.p-checkbox,
.p-inputtext {
    border-radius: 0.25rem;
}

.p-radiobutton.p-invalid > .p-radiobutton-box,
.p-checkbox.p-invalid > .p-checkbox-box,
.p-invalid {
    border-color: var(--red-200) !important;
}

.p-button.p-button-outlined.p-button-plain:enabled:hover,
.p-button.p-button-outlined.p-button-plain:not(button):not(a):not(.p-disabled):hover {
    color: $primaryColor;
    border-color: $primaryColor;
}