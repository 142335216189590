.inventory-item {
  $root: &;

  &__container {
    width: 100%;
    align-items: center;
  }

  &__amount {
    padding-right: $ANT-spacing-4;
  }

  &__impact {
    text-align: right;
  }

  &__amountInput {
    border-radius: $spacing-xxs;
    margin-right: $ANT-spacing-8;
    width: 100%;
  }

  &__amountInputItem {
    width: calc(100% - #{$spacing-4xl});
    padding: 0;
  }

  &__amountButton {
    font-size: $font-size-s;

    svg {
      vertical-align: calc(#{$spacing-xs} * -1);
    }
  }

  &__amountButtonItem {
    width: fit-content;
    padding: 0;
    padding-left: $ANT-spacing-8;
    padding-top: $spacing-l;
  }

  &__amountModal {
    margin: 0 !important;
    width: calc(#{$spacing-9xl} * 4) !important;

    .ant-modal-content {
      border-radius: $spacing-xxs;
    }

    .ant-modal-body {
      padding: $spacing-m;
    }

    .ant-form-horizontal {
      display: flex
    }

    .ant-form-item-label, label {
      padding: 0;
      height: $spacing-l;
    }

    .ant-form-item-required:before {
      content: unset !important;
    }
  }

  &__title {
    @extend .crop-text;
    width: $spacing-13xl;
    min-width: 0;
  }

  &__actions {
    visibility: hidden;
  }

  &__actionsWrapper {
    align-items: center;

    button {
      border-radius: $border-radius !important;
      background-color: transparent;
      border-color: $color-grey-dark !important;
      &:hover {
        transition: none;
        background-color: $color-steel-light;
        color: $color-grey-dark;
      }
    }
  }

  &__value {
    display: inline-block;

    &--opened,
    &:hover {
      color: $color-blue;
    }
  }

  &__actionsMenu {
    display: flex;
    align-items: center;
    margin-left: $ANT-spacing-8;

    .ant-btn-text:first-child {
      display: none;
    }

    button {
      border-color: transparent !important;
      &:hover {
        border-color: transparent !important;
      }
    }
  }

  &__addImpact {
    padding: 0;
    margin: 0;
    border: none;
    opacity: 0.7;
    color: $color-blue;

    &:hover{
      opacity: 1;
      color: $color-blue !important;
    }
  }
  
  &.ant-tree-treenode-selected {
    #{$root}__actions {
      opacity: 1;
    }
  }

  &:hover,
  &.ant-tree-treenode-selected {
    #{$root}__actions {
      visibility: visible;
    }

    #{$root}__value {
      text-decoration: underline;
    }
  }
}