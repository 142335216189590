.custom-impacts {
  padding: $grid-padding 0;

  &__save,
  &__form,
  &__header {
    width: 50%;
  }

  &__form {
    margin-bottom: $spacing-sm;
  }

  &__save {
    text-align: right;

    button {
      margin-left: $ANT-spacing-8;
    }
  }

  &__info{
    display: inline-block;
    padding-right: $spacing-l;
    padding-bottom: $spacing-ml;
  }
}