$spacing-xs:   2px;
$spacing-xxs:  4px;
$spacing-s:    5px;
$spacing-ss:   6px;
$spacing-sm:   10px;
$spacing-m:    15px;
$spacing-ml:   20px;
$spacing-l:    25px;
$spacing-xl:   30px;
$spacing-2xl:  35px;
$spacing-3xl:  40px;
$spacing-4xl:  45px;
$spacing-5xl:  50px;
$spacing-6xl:  55px;
$spacing-7xl:  60px;
$spacing-8xl:  65px;
$spacing-9xl:  70px;
$spacing-10xl: 75px;
$spacing-11xl: 80px;
$spacing-12xl: 85px;
$spacing-13xl: 90px;

// ANT DEFAULT SIZES
$ANT-spacing-4:   4px;
$ANT-spacing-8:   8px;
$ANT-spacing-16:  16px;
$ANT-spacing-18:  18px;
$ANT-spacing-22:  22px;
$ANT-spacing-32:  32px;
$ANT-spacing-24:  24px;
$ANT-spacing-44:  44px;

// MAIN LAYOUT
$grid-padding: $ANT-spacing-16;
$grid-padding-x2: calc(#{$grid-padding} * 2);
$grid-padding-x4: calc(#{$grid-padding} * 4);
$border-radius: $ANT-spacing-4;

// EXTRA CONTENT
$chatbot-button-height: 60px;

// SIDEBAR
$sidebar-width: 250px;
$sidebar-width-collapsed: 80px;
$sidebar-submenu-width: 300px;
$sidebar-submenu-width-sm: 176px;
$logo-height: $spacing-ml;
$sidebar-padding-bottom: calc(#{$chatbot-button-height} + #{$spacing-8xl});
$sidebar-padding: $ANT-spacing-16;
$sidebar-icon-margin: 10px;
$sidebar-header-height: 52px;

// CONTENT
$title-header-height: 100px;
$modal-title-header-height: 50px;

$product-conversion-name-width: 160px;
$minimum-product-width: 300px;
$maximum-product-width: 500px;

$comparison-searchbox-width: 250px;
$comparison-searchbox-width-md: 180px;
$comparison-searchbox-width-sm: 150px;

$coupon-input-width: 200px;

$impact-bars-width: 160px;

$tree-item-height: 36px;

// FORM
$form-padding: $ANT-spacing-16;

// DETAILS PANEL
$icon-button-size: 30px;
$details-panel-width: 342px;
$details-panel-title-height: 73px;
$details-panel-inventory-gap: 160px;

// MODAL WINDOW
$modal-banner-padding: 75px;

// CUSTOM ICON
$custom-icon-button-size: 14px;
$custom-icon-button-size-lg: 18px;
