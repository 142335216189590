.model .workspace > .process .process-col.input-list {
  text-align: right;
    button.add {
        padding-right: $spacing-2xl;
        text-align: right;
    }
    .edit {
        > input,
        .autocomplete input {
            text-align: right;
        }
    }
    .item {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        &:hover:not(.edit), &.on {
            background-color: $color-steel-light;
        }

        .details-icons {
            position: absolute;
            top: $spacing-s;
            left: 0;
        }
    }
}
