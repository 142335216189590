.reference-property-dialog {
  width : 611px;

  &__wrap {
    padding : $spacing-m;
  }

  &__title {
    padding: $spacing-sm 0;
    font-size: $font-modal-dialog-title;
    line-height: $spacing-xl;
    font-weight: 500;
    color: $color-grey-dark;
  }

  &__info {
    padding: $spacing-sm 0;
    font-size: $font-size-s;
    color: $color-grey;
    margin: 0;
  }

  &__save {
    padding: $spacing-sm 0
  }

  &__formItem {
    width: 50%;
    padding: $spacing-m $ANT-spacing-8 $spacing-m 0;
    float: left;

    &--full {
      width: 100%;
    }

    .ant-input-number,
    .ant-select-selector {
      border-radius: $spacing-xxs !important;
    }

    .ant-input-number {
      width: 100%;
    }
  }

  .ant-form-item-label {
    margin: 0;
    padding: 0;

    label {
      color: $color-grey-dark;
      height: $spacing-ml;

      &::before {
        content: unset !important;
      }
    }
  }
}