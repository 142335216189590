$font-family-primary: 'Gilroy ExtraBold', sans-serif; // Headers and other big things ('furniture')
$font-family-secondary: 'Gilroy ExtraBold', sans-serif; // Subheaders, captions, quotes, etc.
// $font-family-tertiary:  'Adelle Sans', sans-serif; // Main text
$font-family-tertiary: 'Rubik', sans-serif; // Main text

$font-family-default: $font-family-tertiary;

$font-size-xxxs: 8px;
$font-size-xxxss: 10px;
$font-size-xxs: 11px;
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-ssm: 15px;
$font-size-sm: 16px;
$font-size-ms: 18px;
$font-size-m: 20px;
$font-size-ml: 25px;
$font-size-mml: 26px;
$font-size-l: 30px;
$font-size-xl: 36px;

$font-header-size: 28px;

$font-size-welcome-title: 48px;
$font-size-welcome-subtitle: 22px;
$font-modal-dialog-title: 24px;

$font-size-page-header-back: 10px;

$semibold: 500;
$thin: 100;

$line-height-normal: 1.4em;
$line-height-tall: 1.8em;

@import url('https://fonts.googleapis.com/css?family=Rubik:400,500,700');

@font-face {
  font-family: 'Adelle Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Adelle Sans'),
    url('../../static/font/adellesans-regular-webfont.ttf');
}

@font-face {
  font-family: 'Gilroy ExtraBold';
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy ExtraBold'), url('../../static/font/gilroy-extrabold.otf');
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.crop-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.page-header-title {
  font-family: $font-family-tertiary;
  font-size: $font-header-size;
  font-weight: $semibold;
  color: $color-navy;
}

.page-title {
  font-family: $font-family-tertiary;
  font-size: $font-size-s;
  font-weight: 400;
  color: #666666;
  padding-left: 8px;
  position: relative;
  bottom: -8px;
}

.card-title-big {
  .ant-card-head-title {
    font-size: $font-size-ms;
    font-weight: normal;
  }
}
