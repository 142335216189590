$primaryColor: #0C7DD0;
$primaryDarkColor: #072B49;
$primaryDarkerColor: #203561;
$primaryTextColor: #ffffff;
$secondaryColor: #607D8B;
$secondaryDarkColor: #455A64;

$colors: (
    "blue": $primaryColor,
    "green": #56CA19,
    "yellow": #E7A04D,
    "cyan": #00BCD4,
    "pink": #B8555B,
    "indigo": #3F51B5,
    "teal": #009688,
    "orange": #FF6010,
    "bluegray": #516B80,
    "purple": #8B6E89,
    "red": #F5222D,
    "primary": $primaryColor,
    "primarydark": $primaryDarkColor
);

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';

:root {
    --surface-a:#ffffff;
    --surface-b:#f8f9fa;
    --surface-c:#e9ecef;
    --surface-d:#dee2e6;
    --surface-e:#ffffff;
    --surface-f:#ffffff;
    --text-color:#495057;
    --text-color-secondary:#6c757d;
    --primary-color: #0060AE;
    --primary-color-text:#ffffff;
    --font-family:-apple-system, BlinkMacSystemFont, 'Rubik', sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --surface-0: rgba(255, 255, 255, 0);
    --surface-50: rgba(255, 255, 255, .1);
    --surface-100: rgba(255, 255, 255, .2);
    --surface-200: rgba(255, 255, 255, .3);
    --surface-300: rgba(255, 255, 255, .4);
    --surface-400: rgba(255, 255, 255, .5);
    --surface-500: rgba(255, 255, 255, .6);
    --surface-600: rgba(255, 255, 255, .7);
    --surface-700: rgba(255, 255, 255, .8);
    --surface-800: rgba(255, 255, 255, .9);
    --surface-900: rgba(255, 255, 255, 1);
    --gray-50:#F7F7F7;
    --gray-100:#D0D0D0;
    --gray-200:#B6B6B6;
    --gray-300:#9C9C9C;
    --gray-400:#828282;
    --gray-500:#686868;
    --gray-600:#4E4E4E;
    --gray-700:#343434;
    --gray-800:#1A1A1A;
    --gray-900:#000000;
    --content-padding:#{$panelContentPadding};
    --inline-spacing:#{$inlineSpacing};
    --border-radius:#{$borderRadius};
    --surface-ground:#f8f9fa;
    --surface-section:#ffffff;
    --surface-card:#ffffff;
    --surface-overlay:#ffffff;
    --surface-border:#dee2e6;
    --surface-hover:#e9ecef;
    --maskbg: #{$maskBg};
    --focus-ring: #{$focusShadow};
    color-scheme: light;
}
