.search-filter {

  &__header {
    width: 100%;
    font-size: $font-size-sm;
    font-weight: $semibold;
    margin-bottom: $spacing-xxs;
    display: flex;
    flex-direction: row;

    &--clear {
      text-align: right;
      flex-grow: 2;
      font-weight: normal;
    }
  }


  &__badge {
    margin-left: $spacing-sm;

    .ant-badge-count {
      background: $color-green;
    }
  }

  &__badge {
    margin-left: $spacing-sm;

    .ant-badge-count {
      background: $color-green;
    }
  }

  &__section {
    padding: $spacing-sm $ANT-spacing-24 $spacing-l;
    border-radius: $spacing-ss;
    background: $color-grey-bright;
    margin: 0;
  }

  &__title {
    padding: $ANT-spacing-16 0 $spacing-s;
    
    h3 {
      font-weight: $semibold;
      font-size: $font-size-sm;
      font-family: $font-family-default;
      color: $color-text-grey-dark;
    }
  }

  &__content {
    padding: $spacing-s 0 $ANT-spacing-16;

    &--database,
    &--elementary {
      padding-right: $spacing-ml;
      overflow-x: hidden;

      .ant-tree-treenode {
        width: 100%;
      }

      .ant-tree-node-content-wrapper {
        max-width: calc(100% - #{$ANT-spacing-44});
      }
    }

  }

  &__form {
    padding: 0;
  }

  &__category {
    background: none;
  }

  &__dropdown {
    border: none;
    background: none;
    box-shadow: none;
    padding: 0;
    color: $color-blue;
    width: fit-content;
    max-width: 100%;
    height: 20px;

    &:hover,
    &:focus {
      background: none !important;
    }

    svg {
      vertical-align: calc(#{$spacing-xs} * -1);
    }
  }

  &__checkboxGroup {
    width: 100%;
  }

  &__selected {
    @extend .crop-text;
    float: left;
    display: block !important;
    width: calc(100% - #{$spacing-s});
    text-align: left;
    line-height: 23px;
  }

  &__dropdownOverlay {
    padding: 0;

    .ant-checkbox-wrapper {
      width: 100%;

      span ~ span {
        flex-grow: 2;
      }
    }

    .ant-menu-item {
      padding: 0;
      margin: 0 !important;
      background: none !important;
    }
  }
}