form.create-workspace {
    min-width: 300px;
  }

  .model {
    // display: flex;
    // flex-grow: 1;
    width: 100%;
    h2 {
        padding: $spacing-ml;
    }

    .ws-container{
      transition: all 300ms;
      &.no-sidebar{
          width: 100%;
      }
      > div {
          height: 100%;
      }
      .workspace {
          padding: $spacing-ml;
          @include breakpoint('laptop'){
              padding: $spacing-sm;
          }
          transition: all 300ms;
          &.no-details-panel {
              width: 100%;
          }
          > .process {
              display: flex;
              .process-col {

                  button.add {
                      height: $spacing-5xl;
                      width: 100%;
                      &:active {
                          box-shadow: initial;
                          transform: initial;
                      }
                      &.dashed {
                          border-color: $color-steel;
                          &:hover::after {
                              box-shadow: 0px 0px 0px 1px $color-navy;
                          }
                      }
                  }

              }
          }

      }
      }
  }

.process-navigation {
  align-items: center;
  background-color: $color-steel-lighter;
  border-top-left-radius: $spacing-m;
  border-top-right-radius: $spacing-m;
  display: flex;
  margin: $spacing-ml $spacing-sm $spacing-ml $spacing-ml;
  h1 {
      margin: $spacing-sm $spacing-2xl $spacing-sm $spacing-ml;
      padding: 0px;
      position: relative;
      &.sep{
          cursor: pointer;
          &::after {
              background-color: $color-background;
              content: '';
              height: $spacing-8xl;
              margin: 0px $spacing-ml;
              position: absolute;
              right: -#{$spacing-6xl};
              top: -#{$spacing-sm};
              transform: skewX(-15deg);
              width: $spacing-sm;
          }
      }
  }
}
