.move-item-dialog {
    width: 724px !important;

    .blue-button:not(:disabled) {
        background: $color-blue-dark;
        color: $color-white;
        border: none;

        &:hover {
            background: $color-blue-v1;
            color: $color-white;
            border: none;
        }
    }

    .blue-button:disabled {
        border: 1px solid $color-grey-dark;
        color: $color-grey-dark;
    }

    &__wrap {
        padding : $spacing-sm;
    }

    &__heading {
        display: flex;
        width: 100%;
        height: 45px;
        padding-top: 15px;
    }

    &__content {
        width: 100%;
        height: 400px;
        overflow: auto;
        padding-right: $spacing-s;
    }

    &__searchBar {
        flex-grow: 2;
    }

    &__moveToOverview {
        width: fit-content;

        button {
            @extend .blue-button;
        }
    }

    &__title {
        padding: 0 0 $spacing-ml;
        font-size: $font-modal-dialog-title;
        line-height: $spacing-xl;
        font-weight: 500;
        color: $color-grey-dark;

    }

    &__separator {
        padding: 3px 5px;
    }

    &__breadcrumbs {
        display: flex;
        width: 100%;
        font-size: 14px;
    }

    &__parent {
        color: #AFAFAF;
        cursor: pointer;
    }

    &__current {
        color: #595959;
    }

    &__item {
        display: flex;
        width: 100%;
        padding: 14px !important;
        border-radius: $spacing-ss;
        background: $color-grey-bright;
        border: none;
        margin-top: $ANT-spacing-8;
        align-items: flex-start !important;
        cursor: pointer;
        clear: both;

        &:hover {
          background-color: $color-grey-bright-1;
        }

        button {
            @extend .blue-button;
        }
    }

    &__icon{
        width: fit-content;
        margin-right: $spacing-sm;
    }

    &__label {
        flex-grow: 2;
        padding: $spacing-s;
    }

    &__action {
        width: fit-content;
        margin-left: $spacing-sm;
        display: none;

        &--open {
            display: flex;
        }
    }

    .ant-avatar-square {
        background: $color-blue-dark-v1;
        border-radius: 5px;
        padding-top: 5px;
    }
}