$button-padding: $spacing-s $spacing-sm;
.button {
	border-radius: $border-radius;
	min-height: $spacing-xl;
	min-width: $spacing-xl;
	cursor: pointer;
	padding: $button-padding;
	position: relative;
  	transition: background-color $default-animation-speed, border-color $default-animation-speed, box-shadow $default-animation-speed, opacity $default-animation-speed, transform $default-animation-speed;

	&:active {
		transform: translateY(2px);
  	}

	&:disabled {
		cursor: default;
		opacity: .5;
	}

	&.dashed {
		border-style: dashed;
		border-width: 1px;
		&:hover {
			border-style: solid;
		}
  }

  &.primary,
  &.secondary,
  &.tertiary,
  &.danger {
    color: $color-text-inverse;
    border: none;
    &:hover:not(:disabled) {
      color: $color-text-inverse;
    }
    &:focus:not(:disabled){
      color: $color-text-inverse;
    }
  }

	&.primary {
		background-color: $color-green;
		&:hover:not(:disabled) {
			background-color: lighten($color-green, 5%);
    }
    &:focus:not(:disabled){
      color: $color-text-inverse;
    }
  }

	&.secondary {
		background-color: $color-navy;
		&:hover:not(:disabled) {
			background-color: lighten($color-navy, 5%);
		}
  }

	&.tertiary {
    background-color: $color-orange;
		&:hover:not(:disabled) {
      background-color: lighten($color-orange, 5%);
		}
  }

  &.danger {
    background-color: $color-red;
		&:hover:not(:disabled) {
      background-color: lighten($color-red, 5%);
		}
	}

	&.transparent {
		border: none;
		background-color: transparent;
		box-shadow: none;
		color: $color-text;
	}

	&.quaternary {
		background-color: $color-red;
		color: $color-text-inverse;
		&:hover:not(:disabled) {
			background-color: lighten($color-red, 5%);
		}
  }
  &.icon{
    min-width: 0;
    min-height: 0;
    width: auto;
    height: auto;
    border-radius: 0;
    box-shadow: none;
    padding: 0px;
    vertical-align: 0.5em;
  }
  &.gutter{
    display: inline-block;
    margin-right: $spacing-sm;
    margin-bottom: $spacing-sm;
  }
  &.inverse,
  &.inverse:hover{
    color: $color-white;
    border-color: $color-white;
    background-color: transparent;
  }
}

.empty-button {
	background-color: transparent;
	border: none;
	box-shadow: none;
	padding: 0;
	&:hover, &:active, &:focus {
		background-color: transparent;
	}
}

// BUTTON WITH CUSTOM ICON
.button.ant-btn,
.button .ant-btn{
  .custom-icon-container svg{
    height: $custom-icon-button-size;
    width: auto;
    path{
      stroke: $color-text;
    }
  }
  &.ant-btn-lg {
    .custom-icon-container svg{
      height: $custom-icon-button-size-lg;
      width: auto;
    }
  }
  &:hover,
  &:active,
  &:focus{
    .custom-icon-container svg path{
      stroke: $color-text-primary;
    }
  }
  &[disabled]{
    .custom-icon-container svg path{
      stroke: rgba(0, 0, 0, 0.25);
    }
  }
}
